/* global layui $ ERC20 ECCSwap FutureNFT FutureTrade ECCAirdrop FutureNFTSale ProxyAdmin ECCRateRecord ECCStake ECCStakeNFT */
import React, { useState, useEffect, useContext } from 'react';
import myWallet from './utils/mywallet';
import { ethers } from 'ethers';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';
// import { Button } from 'antd';
import cd01 from '../../../src/assets/images/FT_07a.png';
import cd02 from '../../../src/assets/images/FT_06a.png';
import cd03 from '../../../src/assets/images/FT_05a.png';
import './EccNFT.css';


function EccNFTSingle() {
  const { t } = useTranslation('common');
  const {
    account,
    setAccount,
    myWallet,
    admin_addr,
    ecc_addr,
    usdt_addr,
    swap_addr,
    sale_addr,
    nft_addr_1,
    nft_addr_2,
    nft_addr_3,
    trade_addr,
    airdrop_addr_1,
    airdrop_addr_2,
    airdrop_addr_3,
    proxyAdmin_addr_1,
    proxyAdmin_addr_2,
    rateRecord,
    eccStake,
    eccStakeNFT
  } = useContext(ExchangeContext);
  const [balance_1, setBalance_1] = useState("-");
  const [balance_2, setBalance_2] = useState("-");
  const [balance_3, setBalance_3] = useState("-");
  const [loading_recycle_1, setLoading_recycle_1] = useState(false);
  const [loading_recycle_2, setLoading_recycle_2] = useState(false);
  const [loading_recycle_3, setLoading_recycle_3] = useState(false);
  const [loading_tranfer_1, setLoading_tranfer_1] = useState(false);
  const [loading_tranfer_2, setLoading_tranfer_2] = useState(false);
  const [loading_tranfer_3, setLoading_tranfer_3] = useState(false);


  const getSubStr = str => {
    var subStr1 = str.substr(0, 6);
    var subStr2 = str.substr(str.length - 4, 4);
    var subStr = subStr1 + '...' + subStr2;
    return subStr;
  };

  const check_inputs = (inputs, _loading) => {
    if (account == null || _loading) {return}
    let params = [];
    for (let i = 0; i < inputs.length; i++) {
      let input = $(inputs.get(i));
      let placeholder_txt = input.attr("placeholder");
      if (input.val() === '') {
        layui.layer.alert(t(placeholder_txt));
        return
      }
      let param_type = input.attr("param_type");
      switch (param_type) {
        case "string":
        case "address":
          params.push(input.val())
          break;
        case "uint":
          params.push(ethers.BigNumber.from(input.val()))
          break;
        case "amount":
          {
            let decimals = input.attr("decimals");
            params.push(ethers.utils.parseUnits(input.val(), parseInt(decimals)))
          }
          break;
        default:
          break;
      }
    }
    return params
  }

  /*
  const call_method = async (nft_addr, method_name, params, _setloading) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let nft = myWallet.getContract(nft_addr);
    if (nft != null) {
      switch (method_name) {
        case "recycle":
        {
          //estimateGas
          let gas = await nft.estimateGas.recycle(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await nft.recycle(params[0],params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                _setloading(false);
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              _setloading(true);
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };
  */

  const call_transfer = async (nft_addr, params, _setloading) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let nft = myWallet.getContract(nft_addr);
    if (nft != null) {
        let tokenId = await nft.tokenOfOwnerByIndex(account,0).catch((err) => {console.error(err)});
        if (typeof tokenId !== "undefined") {
        //estimateGas
        let gas = await nft.estimateGas.transferFrom(account, params[0], tokenId).catch((err) => {
          console.error(err)
        });
        if (typeof gas != 'undefined') {
          let gaslimit = Math.round(gas.toNumber() * 1.5);
          let tx = await nft.transferFrom(account, params[0], tokenId, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
          let success = "transfer " + t('exchangeContent.success')
          let fail = "transfer " + t('exchangeContent.fail')
          if (typeof tx !== 'undefined') {
            addTxcheck(tx.hash, (receipt) => {
              _setloading(false);
              if (receipt === 1) {
                layui.layer.msg(success);
              }
              else {
                layui.layer.msg(fail);
              }
              update_balance(account);
            });
            _setloading(true);
          }
          else {
            layui.layer.msg(fail);
            return
          }
        }
      }
    }
  };

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt.status);
        clearInterval(ins);
      }
    }, 1000);
  };

  const update_balance = async _account => {
    if (_account == null) {
      return
    };
    let nft_1 = myWallet.getContract(nft_addr_1);
    let nft_2 = myWallet.getContract(nft_addr_2);
    let nft_3 = myWallet.getContract(nft_addr_3);
    let trade = myWallet.getContract(trade_addr);
    if (nft_1 != null && nft_2 != null && nft_3 != null && trade != null) {
      let bb = await nft_1.balanceOf(_account).catch(err => {
        console.error(err);
      });
      if (typeof bb !== 'undefined') {
        setBalance_1(bb.toString());
      }

      let bb2 = await nft_2.balanceOf(_account).catch(err => {
        console.error(err);
      });
      if (typeof bb2 !== 'undefined') {
        setBalance_2(bb2.toString());
      }

      let bb3 = await nft_3.balanceOf(_account).catch(err => {
        console.error(err);
      });
      if (typeof bb3 !== 'undefined') {
        setBalance_3(bb3.toString());
      }
    }
  };

  const account_callback = _account => {
    setAccount(_account);
  };

  const onClickLink = async () => {
    let accounts = await myWallet.connect();
      if (accounts instanceof Array) {
        setAccount(accounts[0]);
      } else {
        setAccount(null);
    }
  };

  

  useEffect(() => {
    update_balance(account);
  }, [account]);

  useEffect(() => {
    layui.use(['element', 'layer', 'util', 'table'], function() {
      var element = layui.element;
      window.$ = layui.$;
      myWallet.init(account_callback);
      myWallet.addContract(usdt_addr, ERC20.abi);
      myWallet.addContract(ecc_addr, ERC20.abi);
      myWallet.addContract(swap_addr, ECCSwap.abi);
      myWallet.addContract(nft_addr_1, FutureNFT.abi);
      myWallet.addContract(nft_addr_2, FutureNFT.abi);
      myWallet.addContract(nft_addr_3, FutureNFT.abi);
      myWallet.addContract(trade_addr, FutureTrade.abi);
      myWallet.addContract(airdrop_addr_1, ECCAirdrop.abi);
      myWallet.addContract(airdrop_addr_2, ECCAirdrop.abi);
      myWallet.addContract(airdrop_addr_3, ECCAirdrop.abi);
      myWallet.addContract(sale_addr, FutureNFTSale.abi);
      myWallet.addContract(proxyAdmin_addr_1, ProxyAdmin.abi);
      myWallet.addContract(proxyAdmin_addr_2, ProxyAdmin.abi);
      myWallet.addContract(rateRecord, ECCRateRecord.abi);
      myWallet.addContract(eccStake, ECCStake.abi);
      myWallet.addContract(eccStakeNFT, ECCStakeNFT.abi);
      // window.addEventListener('resize', () => {
      //   $('div.layui-side').removeClass('side-toleft');
      // });
    });
  }, []);

  return (
    <div className="layui-fluid">
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md12">
            <div className="layui-card" style={{height:50,marginBottom:0}}>
              <div style={{height:'100%',paddingLeft:15,paddingRight:15,display:'flex',alignItems:'center', justifyContent:'flex-end'}}>
                <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}}  onClick={onClickLink}>
                  {account==null?'Connect Wallet':getSubStr(account)}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="layui-row layui-col-space15">
          <div className="layui-hide-xs layui-col-md12">
            <div className="layui-card" style={{marginBottom:0, padding: 25}}>
              <div style={{display:'flex', justifyContent:'space-around'}}>
                <div className='nftcard' style={{display:'flex',flexDirection:'column',alignItems:'center'}} id="nft_1">
                  <div className='cd'>
                      <img src={cd01}></img>
                  </div>
                  <div className='balance'>
                    <span>{t("exchangeContent.balance")}</span>
                    <span>{balance_1}</span>
                  </div>
                  
                  <div className='exchange'>
                    <input type="text" param_type="address" decimals="0" lay-verify="required" placeholder="please input address" autoComplete="off" className="layui-input transfer_1" />
                    <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}} onClick={()=>{
                            let params = check_inputs($("#nft_1 input.transfer_1"), loading_tranfer_1);
                            if (params instanceof Array) {call_transfer(nft_addr_1, params, setLoading_tranfer_1)}
                          }}>
                      {
                        loading_tranfer_1 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                      }
                      {t("exchangeContent.transfer")}
                    </button>
                  </div>
                </div>
                <div className='nftcard' style={{display:'flex',flexDirection:'column',alignItems:'center'}} id="nft_2">
                  <div className='cd'> 
                      <img src={cd02}></img>
                  </div>
                  <div className='balance'>
                      <span>{t("exchangeContent.balance")}</span>
                      <span>{balance_2}</span>
                  </div>
                  
                  <div className='exchange'>
                    <input type="text" param_type="address" decimals="0" lay-verify="required" placeholder="please input address" autoComplete="off" className="layui-input transfer_2" />
                    <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}} onClick={()=>{
                          let params = check_inputs($("#nft_2 input.transfer_2"), loading_tranfer_2);
                          if (params instanceof Array) {call_transfer(nft_addr_2, params, setLoading_tranfer_2)}
                        }}>
                    {
                      loading_tranfer_2 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                    }
                    {t("exchangeContent.transfer")}
                    </button>
                  </div>
                </div>
                <div className='nftcard' style={{display:'flex',flexDirection:'column',alignItems:'center'}} id="nft_3">
                    <div className='cd'> 
                        <img src={cd03}></img>
                    </div>
                    <div className='balance'>
                        <span>{t("exchangeContent.balance")}</span>
                        <span>{balance_3}</span>
                    </div>
                    
                    <div className='exchange'>
                      <input type="text" param_type="address" decimals="0" lay-verify="required" placeholder="please input address" autoComplete="off" className="layui-input transfer_3" />
                      <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}} onClick={()=>{
                            let params = check_inputs($("#nft_3 input.transfer_3"), loading_tranfer_3);
                            if (params instanceof Array) {call_transfer(nft_addr_3, params, setLoading_tranfer_3)}
                          }}>
                      {
                        loading_tranfer_3 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                      }
                      {t("exchangeContent.transfer")}
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="layui-hide-md layui-col-xs12">
            <div className="layui-card" style={{marginBottom:0, padding: 25}}>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div className='nftcard' style={{display:'flex',flexDirection:'column',alignItems:'center'}} id="nft_1_md">
                  <div className='cd'>
                      <img src={cd01}></img>
                  </div>
                  <div className='balance'>
                    <span>{t("exchangeContent.balance")}</span>
                    <span>{balance_1}</span>
                  </div>
                  
                  <div className='exchange'>
                    <input type="text" param_type="address" decimals="0" lay-verify="required" placeholder="please input address" autoComplete="off" className="layui-input transfer_1" />
                    <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}} onClick={()=>{
                            let params = check_inputs($("#nft_1_md input.transfer_1"), loading_tranfer_1);
                            if (params instanceof Array) {call_transfer(nft_addr_1, params, setLoading_tranfer_1)}
                          }}>
                      {
                        loading_tranfer_1 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                      }
                      {t("exchangeContent.transfer")}
                    </button>
                  </div>
                </div>
                <div className='nftcard' style={{display:'flex',flexDirection:'column',alignItems:'center'}} id="nft_2_md">
                  <div className='cd'> 
                      <img src={cd02}></img>
                  </div>
                  <div className='balance'>
                      <span>{t("exchangeContent.balance")}</span>
                      <span>{balance_2}</span>
                  </div>
                  
                  <div className='exchange'>
                    <input type="text" param_type="address" decimals="0" lay-verify="required" placeholder="please input address" autoComplete="off" className="layui-input transfer_2" />
                    <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}} onClick={()=>{
                          let params = check_inputs($("#nft_2_md input.transfer_2"), loading_tranfer_2);
                          if (params instanceof Array) {call_transfer(nft_addr_2, params, setLoading_tranfer_2)}
                        }}>
                    {
                      loading_tranfer_2 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                    }
                    {t("exchangeContent.transfer")}
                    </button>
                  </div>
                </div>
                <div className='nftcard' style={{display:'flex',flexDirection:'column',alignItems:'center'}} id="nft_3_md">
                  <div className='cd'> 
                      <img src={cd03}></img>
                  </div>
                  <div className='balance'>
                      <span>{t("exchangeContent.balance")}</span>
                      <span>{balance_3}</span>
                  </div>
                  
                  <div className='exchange'>
                      <input type="text" param_type="address" decimals="0" lay-verify="required" placeholder="please input address" autoComplete="off" className="layui-input transfer_3" />
                      <button className="btn btn-primary" type="button" style={{marginRight:10,height:20}} onClick={()=>{
                            let params = check_inputs($("#nft_3_md input.transfer_3"), loading_tranfer_3);
                            if (params instanceof Array) {call_transfer(nft_addr_3, params, setLoading_tranfer_3)}
                          }}>
                      {
                        loading_tranfer_3 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                      }
                      {t("exchangeContent.transfer")}
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default EccNFTSingle;
