/* global layui $ */
import React, { useState, useEffect, useContext, useRef } from 'react';
import myWallet from './utils/mywallet';
import { ethers } from 'ethers';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { Upload,Button,message,Modal  } from 'antd';
import { UploadOutlined  } from '@ant-design/icons';
import * as d3 from 'd3';


function Console() {
  const { t } = useTranslation('common');
  const { account, ecc_addr, sale_addr, airdrop_addr_1, trade_addr, usdt_addr, proxyAdmin_addr_1, proxyAdmin_addr_2} = useContext(ExchangeContext);
  const [totalusdt, setTotalusdt] = useState("-");
  const [totalecc, setTotalecc] = useState("-");
  const [totallockecc, setTotallockecc] = useState("-");
  const [locklist, setlocklist] = useState([]);
  const [loading_lockecc, setloading_lockecc] = useState(false);
  const [loading_lockeccbatch, setloading_lockeccbatch] = useState(false);
  const [loading_setTrader, setloading_setTrader] = useState(false);
  const [loading_setTraderbatch, setloading_setTraderbatch] = useState(false);
  const [isOwner, setisOwner] = useState(true);
  const [loading_setTraderbatch_cvs, setloading_setTraderbatch_cvs] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cvs_content, setCvs_content] = useState("");
  const addresses = useRef(null)
  const amounts = useRef(null)
  const lock_percents = useRef(null)
  const unlock_starts = useRef(null)
  const unlock_finishs = useRef(null)


  const getSubStr = str => {
    var subStr1 = str.substr(0, 6);
    var subStr2 = str.substr(str.length - 4, 4);
    var subStr = subStr1 + '...' + subStr2;
    return subStr;
  };

  const getdate = (timestamp) => {
    //美国东部时间
    if (timestamp === 0) return 0;
    let date = new Date(timestamp*1000 - (12*3600*1000));
    let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    return date2;


    //北京时间
    // if (timestamp === 0) return 0;
    // let date = new Date(timestamp*1000);
    // let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    // return date2;
  }

  const check_inputs = (inputs, element) => {
    if (account == null || !element.is(":hidden")) {return}
    let params = [];
    for (let i = 0; i < inputs.length; i++) {
      let input = $(inputs.get(i));
      let placeholder_txt = input.attr("placeholder");
      if (input.val() === '') {
        layui.layer.alert(t(placeholder_txt));
        return
      }
      let param_type = input.attr("param_type");
      switch (param_type) {
        case "string":
        case "address":
          params.push(input.val())
          break;
        case "uint":
          params.push(ethers.BigNumber.from(input.val()))
          break;
        case "amount":
          {
            let decimals = input.attr("decimals");
            params.push(ethers.utils.parseUnits(input.val(), parseInt(decimals)))
          }
          break;
        case "boolean":
          {
            let _bool = parseInt(input.val())
            if (_bool === 0) {
              params.push(false)
            }
            else {
              params.push(true)
            }
          }
          break;
        case "array_address":
          {
            let array = JSON.parse(input.val());
            if (array instanceof Array) {
              params.push(array)
            }
            else {
              return
            }
          }
          break;
        case "array_uint":
          {
            let array = JSON.parse(input.val());
            if (array instanceof Array) {
              for (let i = 0; i< array.length; i++) {
                array[i] = ethers.BigNumber.from(array[i])
              }
              params.push(array)
            }
            else {
              return
            }
          }
          break;
        case "array_amount":
          {
            let decimals = input.attr("decimals");
            let array = JSON.parse(input.val());
            if (array instanceof Array) {
              for (let i = 0; i< array.length; i++) {
                array[i] = ethers.utils.parseUnits(array[i].toString(), parseInt(decimals))
              }
              params.push(array)
            }
            else {
              return
            }
          }
          break;
        default:
          break;
      }
    }
    return params
  }

  const call_airdrop = async (method_name, params, element) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let airdrop = myWallet.getContract(airdrop_addr_1);
    if (airdrop != null) {
      switch (method_name) {
        case "airDrop":
        {
          let gas = await airdrop.estimateGas.airDrop(params[0],params[1],params[2],params[3],params[4]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.airDrop(params[0],params[1],params[2],params[3],params[4], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "airDropBatch":
        {
          let gas = await airdrop.estimateGas.airDropBatch(params[0],params[1],params[2],params[3],params[4]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.airDropBatch(params[0],params[1],params[2],params[3],params[4], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "transferOwnership":
        {
          let gas = await airdrop.estimateGas.transferOwnership(params[0]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.transferOwnership(params[0], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "withdrawALLToken":
        {
          let ecc = myWallet.getContract(ecc_addr);
          if (ecc != null) {
            let balance1 = await ecc.balanceOf(airdrop_addr_1).catch((err) => {console.error(err)})
            if (typeof balance1 !== "undefined") {
              let gas = await airdrop.estimateGas.withdrawALLToken(balance1).catch((err) => {
                console.error(err)
                layui.layer.msg(err.error.message);
              });
              if (typeof gas != 'undefined') {
                let gaslimit = Math.round(gas.toNumber() * 1.5);
                let tx = await airdrop.withdrawALLToken(balance1, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
                let success = method_name + " " + t('exchangeContent.success')
                let fail = method_name + " " + t('exchangeContent.fail')
                if (typeof tx !== 'undefined') {
                  addTxcheck(tx.hash, (receipt) => {
                    element.hide();
                    if (receipt === 1) {
                      layui.layer.msg(success);
                    }
                    else {
                      layui.layer.msg(fail);
                    }
                    update_balance(account);
                  });
                  element.css("display","inline-block");
                }
                else {
                  layui.layer.msg(fail);
                  return
                }
              }
            } 
          }
        }
        break;
        case "show_LockInfo_admin":
        {
          element.css("display","inline-block");
          let lockIds = await airdrop["show_lockTimestampMap(address)"](params[0]).catch(err => {console.error(err)});
          if (typeof lockIds !== "undefined") {
            let orders = [];
            for (let i = 0; i < lockIds.length; i++) {
              let time = lockIds[i].toNumber();
              let lockinfo = await airdrop["show_LockInfo(address,uint256)"](params[0],time).catch(err => {console.error(err)});
              if (typeof lockinfo !== "undefined") {
                if (lockinfo.active) {
                  let released = await airdrop["show_release(address,uint256)"](params[0],time).catch(err => {console.error(err)});
                  let obj = {
                    id:time,
                    begin_release:getdate(lockinfo.begin_release.toNumber()),
                    end_release:getdate(lockinfo.end_release.toNumber()),
                    balance:ethers.utils.formatUnits(lockinfo.balance.toString(), 18).toString(),
                    released:ethers.utils.formatUnits(released.toString(), 18).toString()
                  }
                  orders.push(obj);
                }
              }
              else {
                layui.layer.msg("show_LockInfo failed!");
                element.hide();
                return
              }
            }

            let releaseMap = await airdrop["show_releaseMap(address)"](params[0]).catch(err => {console.error(err)});
            if (typeof releaseMap !== "undefined") {
              let obj2 = {
                id:-1,
                released:ethers.utils.formatUnits(releaseMap.toString(), 18).toString()
              }
              orders.push(obj2);
            }

            setlocklist(orders);
            element.hide();
          }
          else {
            layui.layer.msg("show_LockInfo failed!");
            element.hide();
            return
          }
        }
        break;
        case "Upgrade":
        {
          let proxyAdmin = myWallet.getContract(proxyAdmin_addr_2);
          if (proxyAdmin != null) {
            let gas = await proxyAdmin.estimateGas.upgrade(airdrop_addr_1, params[0]).catch((err) => {
              console.error(err)
              layui.layer.msg(err.error.message);
            });
            if (typeof gas != 'undefined') {
              let gaslimit = Math.round(gas.toNumber() * 1.5);
              let tx = await proxyAdmin.upgrade(airdrop_addr_1, params[0], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
              let success = method_name + " " + t('exchangeContent.success')
              let fail = method_name + " " + t('exchangeContent.fail')
              if (typeof tx !== 'undefined') {
                addTxcheck(tx.hash, (receipt) => {
                  element.hide();
                  if (receipt === 1) {
                    layui.layer.msg(success);
                  }
                  else {
                    layui.layer.msg(fail);
                  }
                  update_balance(account);
                });
                element.css("display","inline-block");
              }
              else {
                layui.layer.msg(fail);
                return
              }
            }
          }
        }
        break;
        case "deleteLockInfo":
        {
          let gas = await airdrop.estimateGas.deleteLockInfo(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.deleteLockInfo(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "addReleaseMap":
        {
          let gas = await airdrop.estimateGas.addReleaseMap(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.addReleaseMap(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "subReleaseMap":
        {
          let gas = await airdrop.estimateGas.subReleaseMap(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.subReleaseMap(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const call_Trader = async (method_name, params, element) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let trade = myWallet.getContract(trade_addr);
    if (trade != null) {
      switch (method_name) {
        case "setTrader":
        {
          let gas = await trade.estimateGas.setTrader(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await trade.setTrader(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "setTraderBatch":
        {
          let gas = await trade.estimateGas.setTraderBatch(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await trade.setTraderBatch(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "transferOwnership":
        {
          let gas = await trade.estimateGas.transferOwnership(params[0]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await trade.transferOwnership(params[0], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const call_Sale = async (method_name, params, element) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let contract = myWallet.getContract(sale_addr);
    if (contract != null) {
      switch (method_name) {
        case "withdrawUSDT":
        {
          let gas = await contract.estimateGas.withdrawUSDT().catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await contract.withdrawUSDT({"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "transferOwnership":
        {
          let gas = await contract.estimateGas.transferOwnership(params[0]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await contract.transferOwnership(params[0], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.css("display","inline-block");
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt.status);
        clearInterval(ins);
      }
    }, 1000);
  };

  const update_balance = async _account => {
    if (_account == null) return;
    let usdt = myWallet.getContract(usdt_addr);
    let ecc = myWallet.getContract(ecc_addr);
    if (ecc != null && usdt != null) {
      let balance1 = await ecc.balanceOf(airdrop_addr_1).catch((err) => {console.error(err)})
      if (typeof balance1 !== "undefined") {
        setTotallockecc(ethers.utils.formatUnits(balance1.toString(), 18).toString())
      }

      let balance2 = await usdt.balanceOf(sale_addr).catch((err) => {console.error(err)})
      if (typeof balance2 !== "undefined") {
        setTotalusdt(ethers.utils.formatUnits(balance2.toString(), 18).toString())
      }

      let balance3 = await ecc.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance3 !== "undefined") {
        setTotalecc(ethers.utils.formatUnits(balance3.toString(), 18).toString())
      }
    }
  };

  const onOK_checkCSV = async () => {
    setIsModalVisible(false);
    setloading_setTraderbatch_cvs(true);
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      setloading_setTraderbatch_cvs(false);
      return;
    }

    let airdrop = myWallet.getContract(airdrop_addr_1);
    if (airdrop != null) {
      let method_name = "airDropBatch"
      let gas = await airdrop.estimateGas.airDropBatch(addresses.current,amounts.current,lock_percents.current,unlock_starts.current,unlock_finishs.current).catch((err) => {
        console.error(err)
        layui.layer.msg(err.error.message);
      });
      if (typeof gas != 'undefined') {
        let gaslimit = Math.round(gas.toNumber() * 1.5);
        let tx = await airdrop.airDropBatch(addresses.current,amounts.current,lock_percents.current,unlock_starts.current,unlock_finishs.current, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
        let success = method_name + " " + t('exchangeContent.success')
        let fail = method_name + " " + t('exchangeContent.fail')
        if (typeof tx !== 'undefined') {
          addTxcheck(tx.hash, (receipt) => {
            setloading_setTraderbatch_cvs(false);
            if (receipt === 1) {
              layui.layer.msg(success);
            }
            else {
              layui.layer.msg(fail);
            }
            update_balance(account);
          });
        }
        else {
          layui.layer.msg(fail);
          setloading_setTraderbatch_cvs(false);
        }
      }
      else {
        setloading_setTraderbatch_cvs(false);
      }
    }
    else {
      setloading_setTraderbatch_cvs(false);
    }
  }

  const onCancel_checkCSV = () => {
    setIsModalVisible(false);
  }

  const props = {
    accept: "text/csv",
    showUploadList: false,
    beforeUpload: async (file) => {
        const iscsv = (file.type.toLowerCase() === 'text/csv' || file.type.toLowerCase() === 'application/vnd.ms-excel');
    
        if (!iscsv) {
            message.error(`${file.name} 不是CSV文件, 类型： ${file.type}`);
            return false;
        }
        let objectURL = URL.createObjectURL(file);
        let cvs = await d3.csv(objectURL);
        addresses.current = []
        amounts.current = []
        lock_percents.current = []
        unlock_starts.current = []
        unlock_finishs.current = []
        let content = "";
        for (let i = 0; i < cvs.length; i++) {
            let col = cvs[i];
            if (col.address && col.amount && col.lock_percent && col.unlock_start && col.unlock_finish) {
              addresses.current.push(col.address);
              amounts.current.push(ethers.utils.parseUnits(col.amount, 18));
              lock_percents.current.push(ethers.BigNumber.from(col.lock_percent));
              unlock_starts.current.push(ethers.BigNumber.from(col.unlock_start));
              unlock_finishs.current.push(ethers.BigNumber.from(col.unlock_finish));
              content += col.address + "," + col.amount + "," + col.lock_percent + "," + getdate(col.unlock_start) + "," + getdate(col.unlock_finish) + "\n";
            }
            else {
              message.error("csv 文件缺少字段！")
              return false;
            }
        }

        setCvs_content(content);
        setIsModalVisible(true);
        return false;
    }
  };

  

  useEffect(() => {
    update_balance(account);
  }, [account]);

  return (
    <div className="layui-fluid" id="consoleAdmin">
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md12">
            <div className="layui-card" style={{height:50,marginBottom:0}}>
              <div style={{height:'100%',paddingLeft:15,paddingRight:15,display:'flex',alignItems:'center'}}>
                <span className="font_bold_14" style={{flexShrink:0}}>我的钱包地址 :</span>
                <span className="value layui-hide-xs" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':account}</span>
                <span className="value layui-hide-sm layui-show-xs-inline" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':getSubStr(account)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md4">
            <div className="layui-card">
              <div className="layui-card-header context-flex-space-between">我的 ECC 余额 :
              </div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span className="value" style={{'fontSize':18}}>{totalecc}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="layui-col-md4" id="lock_withdrawALLToken">
            <div className="layui-card">
              <div className="layui-card-header context-flex-space-between">锁仓合约 ECC 余额 :
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                    let element = $("#lock_withdrawALLToken i.layui-icon-loading");
                    call_airdrop("withdrawALLToken", null, element)
                  }}>
                  <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                  提取
                </button>
              </div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span className="value" style={{'fontSize':18}}>{totallockecc}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="layui-col-md4" id="sale_withdrawUSDT">
            <div className="layui-card">
              <div className="layui-card-header context-flex-space-between">销售合约 USDT 余额 :
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                    let element = $("#sale_withdrawUSDT i.layui-icon-loading");
                    call_Sale("withdrawUSDT", null, element)
                  }}>
                  <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                  提取
                </button>
              </div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span className="value" style={{'fontSize':18}}>{totalusdt}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={isOwner ? "layui-col-md12" : "hide"}>
            <div className="layui-card">
              <div className="layui-card-header">锁仓合约管理</div>
              <div className="layui-card-body">
                <div className="layui-row layui-col-space15">
                  <div className="layui-col-md4" id="lockecc">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#lockecc i.layui-icon-loading");
                          let params = check_inputs($("#lockecc input"), element);
                          if (params instanceof Array) {call_airdrop("airDrop", params, element)}
                        }}>
                        <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        {"ECC 锁仓"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.amount')}</span>
                        <input param_type="amount" decimals="18" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.lockpercent')}</span>
                        <input param_type="uint" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.start_release')}</span>
                        <input param_type="uint" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.end_release')}</span>
                        <input param_type="uint" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="lockeccBatch">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#lockeccBatch i.layui-icon-loading");
                          let params = check_inputs($("#lockeccBatch input"), element);
                          if (params instanceof Array) {call_airdrop("airDropBatch", params, element)}
                        }}>
                        <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        {"ECC 批量锁仓"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}[]</span>
                        <input param_type="array_address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.amount')}[]</span>
                        <input param_type="array_amount" decimals="18" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.lockpercent')}[]</span>
                        <input param_type="array_uint" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.start_release')}[]</span>
                        <input param_type="array_uint" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.end_release')}[]</span>
                        <input param_type="array_uint" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="lockeccBatchCSV">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <Upload {...props}>
                          <Button loading={loading_setTraderbatch_cvs} type="primary" block icon={<UploadOutlined />}>{"ECC 批量锁仓 CVS"}</Button>
                      </Upload>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="locktransferOwner">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#locktransferOwner i.layui-icon-loading");
                          let params = check_inputs($("#locktransferOwner input"), element);
                          if (params instanceof Array) {call_airdrop("transferOwnership", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"管理员转移"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="lock_Upgrade">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#lock_Upgrade i.layui-icon-loading");
                          let params = check_inputs($("#lock_Upgrade input"), element);
                          if (params instanceof Array) {call_airdrop("Upgrade", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"合约升级"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="lock_deleteLockInfo">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#lock_deleteLockInfo i.layui-icon-loading");
                          let params = check_inputs($("#lock_deleteLockInfo input"), element);
                          if (params instanceof Array) {call_airdrop("deleteLockInfo", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"删除锁仓"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">Id</span>
                        <input param_type="uint" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="lock_addReleaseMap">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#lock_addReleaseMap i.layui-icon-loading");
                          let params = check_inputs($("#lock_addReleaseMap input"), element);
                          if (params instanceof Array) {call_airdrop("addReleaseMap", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"增加立即释放 ECC"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.amount')}</span>
                        <input param_type="amount" decimals="18" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="lock_subReleaseMap">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#lock_subReleaseMap i.layui-icon-loading");
                          let params = check_inputs($("#lock_subReleaseMap input"), element);
                          if (params instanceof Array) {call_airdrop("subReleaseMap", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"减少立即释放 ECC"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.amount')}</span>
                        <input param_type="amount" decimals="18" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md12" id="locklist">
                    <div className="layui-card">
                      <div className="layui-card-header context-flex-space-between" style={{alignItems:'center',padding:15}}>
                        <span style={{width:140}}>{t('exchangeContent.locklist')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input address_input"></input>
                        <button type="button" className="btn btn-primary" style={{marginLeft:10}}  onClick={()=>{
                            let element = $("#locklist i.layui-icon-loading");
                            let params = check_inputs($("#locklist input.address_input"), element);
                            if (params instanceof Array) {call_airdrop("show_LockInfo_admin", params, element)}
                          }}>
                          {
                            <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                          }
                          {"查询"}
                        </button>
                      </div>
                      <div className="layui-card-body">
                        <div className="layui-row layui-col-space15">
                        {
                          locklist.map((item) => {
                            if (item.id !== -1) {
                              return <div className="layui-col-md4" id={item.id}>
                                    <div className="layui-card">
                                      <div className="layui-card-header">Id：{item.id}</div>
                                        <div className="layui-card-body" style={{padding:'5px 5px'}}>
                                          <div className="context-flex-column" style={{height:'auto'}}>
                                            <div className='context-flex-space-between'>
                                              <span className="value" style={{'fontSize':16}}>解锁开始 :</span>
                                              <span className="value" style={{'fontSize':16}}>{item.begin_release}</span>
                                            </div>
                                            <div className='context-flex-space-between'>
                                              <span className="value" style={{'fontSize':16}}>解锁完成 :</span>
                                              <span className="value" style={{'fontSize':16}}>{item.end_release}</span>
                                            </div>
                                            <div className='context-flex-space-between'>
                                              <span className="value" style={{'fontSize':16}}>已锁定的ECC :</span>
                                              <span className="value" style={{'fontSize':16}}>{item.balance}</span>
                                            </div>
                                            <div className='context-flex-space-between'>
                                              <span className="value" style={{'fontSize':16}}>已解锁的ECC :</span>
                                              <span className="value" style={{'fontSize':16, color:'red'}}>{item.released}</span>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                            }
                            else {
                              return <div className="layui-col-md4">
                                    <div className="layui-card">
                                      <div className="layui-card-header">立即释放 ECC</div>
                                        <div className="layui-card-body" style={{padding:'5px 5px'}}>
                                          <div className="context-flex-column" style={{height:'auto'}}>
                                            <div className='context-flex-space-between'>
                                              <span className="value" style={{'fontSize':16}}>可提取的ECC :</span>
                                              <span className="value" style={{'fontSize':16, color:'red'}}>{item.released}</span>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                            }
                          })
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={isOwner ? "layui-col-md12" : "hide"}>
            <div className="layui-card">
              <div className="layui-card-header">商户合约管理</div>
              <div className="layui-card-body">
                <div className="layui-row layui-col-space15">
                  <div className="layui-col-md4" id="setTrader">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#setTrader i.layui-icon-loading");
                          let params = check_inputs($("#setTrader input"), element);
                          if (params instanceof Array) {call_Trader("setTrader", params, element)}
                        }}>
                        <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        设置商户
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.active')}</span>
                        <input param_type="boolean" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="setTraderbatch">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let params = check_inputs($("#setTraderbatch input"), loading_setTraderbatch);
                          if (params instanceof Array) {call_Trader("setTraderBatch", params, setloading_setTraderbatch)}
                        }}>
                        <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        批量设置商户
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}[]</span>
                        <input param_type="array_address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.active')}</span>
                        <input param_type="boolean" type="number" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                  <div className="layui-col-md4" id="tradetransferOwner">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#tradetransferOwner i.layui-icon-loading");
                          let params = check_inputs($("#tradetransferOwner input"), element);
                          if (params instanceof Array) {call_Trader("transferOwnership", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"管理员转移"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={isOwner ? "layui-col-md12" : "hide"}>
            <div className="layui-card">
              <div className="layui-card-header">销售合约管理</div>
              <div className="layui-card-body">
                <div className="layui-row layui-col-space15">
                  <div className="layui-col-md4" id="sale_tradetransferOwner">
                    <div className="context-flex-column border_context" style={{'height':'auto'}}>
                      <button type="button" className="btn btn-primary" onClick={()=>{
                          let element = $("#sale_tradetransferOwner i.layui-icon-loading");
                          let params = check_inputs($("#sale_tradetransferOwner input"), element);
                          if (params instanceof Array) {call_Sale("transferOwnership", params, element)}
                        }}>
                        {
                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i>
                        }
                        {"管理员转移"}
                      </button>
                      <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                        <span className="param_name">{t('exchangeContent.address')}</span>
                        <input param_type="address" type="text" autoComplete="off" className="layui-input"></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal bodyStyle={{maxHeight:600,overflow:'auto'}} visible={isModalVisible} onOk={onOK_checkCSV} onCancel={onCancel_checkCSV} width={'100%'}>
            <p dangerouslySetInnerHTML={{__html: cvs_content}}></p>
          </Modal>
        </div>  
    </div>
  );
}

export default Console;
