/* eslint-disable jsx-a11y/anchor-is-valid */
/* global layui $ ERC20 ECCSwap FutureNFT FutureTrade ECCAirdrop FutureNFTSale ProxyAdmin ECCRateRecord ECCStake ECCStakeNFT */
import React, { useEffect, useContext, useState } from 'react';
import { ExchangeContext } from '../../../App';
import { useTranslation } from 'react-i18next';
import { useLocation, Route, Switch, Link } from 'react-router-dom';
import ExchangeContent from '../ExchangeContent';
import About from '../About';
import ExchangeSwap from '../ExchangeSwap';
import ExchangeSwap_b from '../ExchangeSwap_b';
import ExchangeSwap_c from '../ExchangeSwap_c';
import EccNFT from '../EccNFT';
import AirDrop from '../AirDrop';
import Console from '../Console';
import EccStake from '../../EccStake';


function ExchangeMain() {
  const {
    account,
    setAccount,
    myWallet,
    admin_addr,
    ecc_addr,
    usdt_addr,
    swap_addr,
    sale_addr,
    nft_addr_1,
    nft_addr_2,
    nft_addr_3,
    trade_addr,
    airdrop_addr_1,
    airdrop_addr_2,
    airdrop_addr_3,
    proxyAdmin_addr_1,
    proxyAdmin_addr_2,
    rateRecord,
    eccStake,
    eccStakeNFT
  } = useContext(ExchangeContext);
  const [islinkWallet, setIslinkWallet] = useState(false);
  const [isOwner, setisOwner] = useState(true);
  const { t, i18n } = useTranslation('common');
  const location = useLocation();

  const account_callback = _account => {
    setAccount(_account);
    if (_account != null) {
      setIslinkWallet(true);

      let owner = false;
      for (let i = 0; i < admin_addr.length; i++) {
        if (admin_addr[i].toLowerCase() === _account.toLowerCase()) {
          owner = true;
          break;
        }
      }
      setisOwner(owner);

    } else {
      setIslinkWallet(false);
      setisOwner(false);
    }
  };

  const onmenuLeft = () => {
    let hide = $('#menuLeft').is(':hidden');
    if (!hide) {
      let left = $('.layui-side').position().left;
      if (left === -300) {
        $('.layui-side').removeClass('side-toleft');
        $('.layui-side').addClass('side-toright');
        $('#menuLeft>i').removeClass('layui-icon-spread-left');
        $('#menuLeft>i').addClass('layui-icon-shrink-right');
      } else if (left === 0) {
        $('.layui-side').removeClass('side-toright');
        $('.layui-side').addClass('side-toleft');
        $('#menuLeft>i').removeClass('layui-icon-shrink-right');
        $('#menuLeft>i').addClass('layui-icon-spread-left');
      }
    }
  };

  const changeLanguage = _lng => {
    i18n.changeLanguage(_lng);
  };

  const onClickLink = async () => {
    if (!islinkWallet) {
      let account = await myWallet.connect();
      if (account instanceof Array) {
        account_callback(account[0]);
      } else {
        account_callback(null);
      }
    } else {
      account_callback(null);
    }
  };

  const onClickMerchant = () => {
    layui.layer.msg(t("exchangeContent.merchant_warring"), {icon: 8}); 
  }

  useEffect(() => {
    account_callback(account);
  }, [account]);

  useEffect(() => {
    layui.use(['element', 'layer', 'util', 'table'], function() {
      var element = layui.element;
      window.$ = layui.$;
      myWallet.init(account_callback);
      myWallet.addContract(usdt_addr, ERC20.abi);
      myWallet.addContract(ecc_addr, ERC20.abi);
      myWallet.addContract(swap_addr, ECCSwap.abi);
      myWallet.addContract(nft_addr_1, FutureNFT.abi);
      myWallet.addContract(nft_addr_2, FutureNFT.abi);
      myWallet.addContract(nft_addr_3, FutureNFT.abi);
      myWallet.addContract(trade_addr, FutureTrade.abi);
      myWallet.addContract(airdrop_addr_1, ECCAirdrop.abi);
      myWallet.addContract(airdrop_addr_2, ECCAirdrop.abi);
      myWallet.addContract(airdrop_addr_3, ECCAirdrop.abi);
      myWallet.addContract(sale_addr, FutureNFTSale.abi);
      myWallet.addContract(proxyAdmin_addr_1, ProxyAdmin.abi);
      myWallet.addContract(proxyAdmin_addr_2, ProxyAdmin.abi);
      myWallet.addContract(rateRecord, ECCRateRecord.abi);
      myWallet.addContract(eccStake, ECCStake.abi);
      myWallet.addContract(eccStakeNFT, ECCStakeNFT.abi);
      window.addEventListener('resize', () => {
        $('div.layui-side').removeClass('side-toleft');
      });
    });
  }, []);

  const onClickItem = e => {
    $(e.currentTarget).addClass('layui-nav-itemed');
  };

  return (
    <>
      <div className="layui-header">
        <div className="layui-logo layui-hide-xs">
          <img
            src="/img/logo-epochal-2.png"
            alt=""
            style={{ width: 120, marginRight: 40 }}
          ></img>
        </div>
        <ul className="layui-nav layui-layout-left">
          <li
            id="menuLeft"
            style={{ lineHeight: '56px' }}
            className="layui-nav-item layui-show-xs-inline-block layui-hide-sm"
            lay-header-event="menuLeft"
            onClick={onmenuLeft}
          >
            <i
              style={{ fontSize: '24px', color: '#333' }}
              className="layui-icon layui-icon-spread-left"
            ></i>
          </li>
        </ul>
        <ul className="layui-nav layui-layout-right">
          <li
            className="layui-nav-item"
            style={{ marginRight: 40, color: '#000' }}
          >
            <i
              className={`layui-icon ${
                islinkWallet ? 'layui-icon-ecc' : 'layui-icon-unlink'
              }`}
              style={{ fontSize: 26, position: 'absolute', left: -15 }}
            ></i>
            <a href="javascript:;" className="nodot" style={{ color: '#000' }}>
              {islinkWallet
                ? t('exchangeMain.connect')
                : t('exchangeMain.discon')}
            </a>
            {islinkWallet === false ? (
              <dl className="layui-nav-child">
                <dd>
                  <a
                    className="nodot"
                    href="javascript:;"
                    onClick={onClickLink}
                  >
                    {t('exchangeMain.linkWallet')}
                  </a>
                </dd>
              </dl>
            ) : (
              <dl className="layui-nav-child">
                <dd>
                  <a
                    className="nodot"
                    href="javascript:;"
                    onClick={onClickLink}
                  >
                    {t('exchangeMain.unlinkWallet')}
                  </a>
                </dd>
              </dl>
            )}
          </li>
          <li className="layui-nav-item" style={{ color: '#000' }}>
            <i
              className="layui-icon layui-icon-website"
              style={{ fontSize: 26, position: 'absolute', left: -15 }}
            ></i>
            <a href="javascript:;" className="nodot" style={{ color: '#000' }}>
              {t('exchangeMain.language')}
            </a>
            <dl className="layui-nav-child">
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('en')}
                >
                  EN
                </a>
              </dd>
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('jp')}
                >
                  JP
                </a>
              </dd>
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('cn')}
                >
                  CN
                </a>
              </dd>
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('sa')}
                >
                  SA
                </a>
              </dd>
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('fr')}
                >
                  FR
                </a>
              </dd>
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('de')}
                >
                  DE
                </a>
              </dd>
              <dd>
                <a
                  className="nodot"
                  href="javascript:;"
                  onClick={() => changeLanguage('bd')}
                >
                  BD
                </a>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
      <div
        className="layui-side layui-bg-black"
        style={{ borderLeft: '2px solid #5d78ff', boxSizing: 'border-box' }}
      >
        <div className="layui-side-scroll">
          <ul className="layui-nav layui-nav-tree">
            <li
              className="layui-nav-item layui-nav-itemed"
              onClick={onClickItem}>
              <Link
                to="/"
                style={{ paddingLeft: 44 }}
                onClick={onmenuLeft}
              >
                <i
                  className="layui-icon layui-icon-home"
                  style={{ marginRight: 10 }}
                ></i>
                {t('exchangeMain.ContractCenter')}
              </Link>
              {/* <a className="" ref="javascript:;">
                <i
                  className="layui-icon layui-icon-home"
                  style={{ marginRight: 10 }}
                ></i>
                {t('exchangeMain.ContractCenter')}
              </a> */}
              <dl className="layui-nav-child">
                {/* <dd className={location.pathname === '/' ? 'layui-this' : ''}>
                  <Link to="/" style={{ paddingLeft: 44 }} onClick={onmenuLeft}>
                    {t('exchangeMain.introdution')}
                  </Link>
                </dd> */}
                <dd className={location.pathname === '/console' ? 'layui-this' : ''}>
                  <Link
                    to="/console"
                    style={{ paddingLeft: 44 }}
                    onClick={onmenuLeft}
                  >
                    {t('exchangeMain.my_ecc')}
                  </Link>
                </dd>
                <dd className={location.pathname === '/nft' ? 'layui-this' : ''}>
                  <Link
                    to="/nft"
                    style={{ paddingLeft: 44 }}
                    onClick={onmenuLeft}
                  >
                    {t('exchangeMain.my_nft')}
                  </Link>
                </dd>
                <dd className={location.pathname === '/lock' ? 'layui-this' : ''}>
                  <Link
                    to="/lock"
                    style={{ paddingLeft: 44 }}
                    onClick={onmenuLeft}
                  >
                    {t('exchangeMain.my_lock')}
                  </Link>
                </dd>
                <dd className={location.pathname === '/stake' ? 'layui-this' : ''}>
                  <Link
                    to="/stake"
                    style={{ paddingLeft: 44 }}
                    onClick={onmenuLeft}
                  >
                    {t('exchangeMain.my_stake')}
                  </Link>
                </dd>
                <dd className={isOwner ? (location.pathname === '/admin' ? 'layui-this' : '') : 'hide'}>
                  <Link
                    to="/admin"
                    style={{ paddingLeft: 44 }}
                    onClick={onmenuLeft}
                  >
                  Admin Console
                  </Link>
                </dd>
              </dl>
            </li>
            <li className="layui-nav-item layui-nav-itemed">
              <a href='https://pancakeswap.finance/swap'
                    target="_blank"
                    style={{ paddingLeft: 44 }}
                  >
                <i className="layui-icon layui-icon-dollar" style={{ marginRight: 10 }}></i>
                {t('exchangeMain.exchange_ecc')}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="layui-body">
        <Switch>
          <Route exact path={'/'}>
            <About></About>
          </Route>
          <Route exact path={'/console'}>
            <ExchangeContent></ExchangeContent>
          </Route>
          {/* <Route exact path={'/admin'}>
                <ExchangeAdmin></ExchangeAdmin>
            </Route> */}
          <Route exact path={'/swap'}>
            <ExchangeSwap></ExchangeSwap>
          </Route>
          <Route exact path={'/swap_b'}>
            <ExchangeSwap_b></ExchangeSwap_b>
          </Route>
          <Route exact path={'/swap_c'}>
            <ExchangeSwap_c></ExchangeSwap_c>
          </Route>
          <Route exact path={'/nft'}>
            <EccNFT></EccNFT>
          </Route>
          <Route exact path={'/lock'}>
            <AirDrop></AirDrop>
          </Route>
          <Route exact path={'/stake'}>
            <EccStake></EccStake>
          </Route>
          <Route exact path={'/admin'}>
            <Console></Console>
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default ExchangeMain;
