/* global layui $ ethers */
import React, { useState,useEffect,useContext}  from 'react';
import myWallet from './utils/mywallet';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { ethers } from "ethers";

function ExchangeContent() {

  const { t } = useTranslation('common');
  const {admin_address, account, ecc_addr, swap_addr} = useContext(ExchangeContext);
  const [totalpoints, setTotalpoints] = useState("-");
  const [lockedpoints, setLockedpoints] = useState("-");
  const [unlockedpoints, setUnlockedpoints] = useState("-");
  const [loading_create_order, setLoading_create_order] = useState(false);
  const [loading_update_order, setLoading_update_order] = useState(false);
  const [loading_withdraw_order, setLoading_withdraw_order] = useState(false);
  // const [loading_transfer, setLoading_transfer] = useState(false);
  const [loading_withdrawERC20, setLoading_withdrawERC20] = useState(false);



  const getSubStr = (str) => {
    var subStr1 = str.substr(0,6);
    var subStr2 = str.substr(str.length-4,4);
    var subStr = subStr1 + "..." + subStr2 ;
    return subStr;
  }

  const getdate = (timestamp) => {
    if (timestamp === 0) return 0;
    let date = new Date(timestamp*1000);
    let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    return date2;
  }

  const check_inputs = (inputs, _loading) => {
    if (account == null || _loading) {return}
    let params = [];
    for (let i = 0; i < inputs.length; i++) {
      let input = $(inputs.get(i));
      let placeholder_txt = input.attr("placeholder");
      if (input.val() === '') {
        layui.layer.alert(t(placeholder_txt));
        return
      }
      let param_type = input.attr("param_type");
      switch (param_type) {
        case "string":
        case "address":
          params.push(input.val())
          break;
        case "uint":
          params.push(ethers.BigNumber.from(input.val()))
          break;
        case "amount":
          {
            let decimals = input.attr("decimals");
            params.push(ethers.utils.parseUnits(input.val(), parseInt(decimals)))
          }
          break;
        default:
          break;
      }
    }
    return params
  }

  const call_method = async (method_name, params, _setloading) => {
    if (account == null) {return}
    let signer =  myWallet.getContract(swap_addr);
    if (signer != null) {
      //estimateGas
      let estimateGas_fun = signer.estimateGas[method_name];
      let gas = await estimateGas_fun.apply(this,params).catch((err) => {console.error(err)});
      if (typeof gas !== "undefined") {
        let gaslimit = Math.round(gas.toNumber() * 2);
        let fun = signer[method_name]
        params.push({"gasLimit":gaslimit});
        let tx = await fun.apply(this,params).catch((err) => {console.error(err)});
        let success = method_name + " " + t('exchangeContent.success')
        let fail = method_name + " " + t('exchangeContent.fail')
        if (typeof tx !== 'undefined') {
          addTxcheck(tx.hash, (receipt) => {
            _setloading(false);
            if (receipt.status === 1) {
              layui.layer.msg(success);
            }
            else {
              layui.layer.msg(fail);
            }
            update_balance(account);
          });
          _setloading(true);
        }
        else {
          layui.layer.msg(fail);
          return
        }
      }
    }
  }

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt);
        clearInterval(ins)
      }
    }, 1000);
  }

  const update_balance = async (_account) => {
    if (_account == null) {return null;}
    let erc20 = myWallet.getContract(ecc_addr);
    //let swap =  myWallet.getContract(swap_addr);
    if (erc20 != null) {
      let balance = await erc20.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance !== "undefined") {
        setTotalpoints(ethers.utils.formatUnits(balance.toString(), 18).toString())
      }

      // let points = await swap.show_points().catch((err) => {console.error(err)})
      // if (typeof points !== "undefined") {
      //   setLockedpoints(ethers.utils.formatUnits(points[1].toString(), 18).toString())
      //   setUnlockedpoints(ethers.utils.formatUnits(points[2].toString(), 18).toString())
      // }

      // let ids = [];
      // let countBN = await swap.getOrder_count().catch((err) => {console.error(err)})
      // if (typeof countBN !== "undefined") {
      //   for (let i = 0; i < countBN.toNumber(); i++) {
      //     let id = await swap.OrderMap(_account, ethers.BigNumber.from(i)).catch((err) => {console.error(err)})
      //     if (typeof id !== "undefined") {
      //       ids.push(id)
      //     }
      //   }
      // }
      // let orders = []
      // let zero_BN = ethers.BigNumber.from(0)
      // for (let i = 0; i < ids.length; i++) {
      //   let order = await swap.allOrders(ids[i]).catch((err) => {console.error(err)})
      //   if (typeof order !== "undefined") {
      //     if (!order.amount.eq(zero_BN)) { 
      //       let obj = {
      //         id:order.id.toNumber(),
      //         amount:ethers.utils.formatUnits(order.amount.toString(), 18).toString(),
      //         address:order.owner,
      //         unlocktime:getdate(order.unlocktime.toNumber())
      //       }
      //       orders.push(obj)
      //     }
      //   }
      // }

      // layui.table.render({
      //   elem: '#orderlist' //指定原始表格元素选择器（推荐id选择器）
      //   ,height: 300 //容器高度
      //   ,skin: 'line' //行边框风格
      //   ,size: 'sm'
      //   ,cols: [[
      //     {field:'id', title:'ID', width:100},
      //     {field:'amount', title:'AMOUNT',width:100},
      //     {field:'address', title:'OWNER'},
      //     {field:'unlocktime', title:'RELEASE TIME'}
      //   ]],
      //   data:orders
      // });
    }
  }

  const onClickFind = async () => {
    if (account == null) {return}
    let orderId = $("#orderId_input").val();
    if (orderId === '') {
      layui.layer.alert('请输入 Id');
      return
    }
    let erc20 = myWallet.getContract(ecc_addr);
    let contract =  myWallet.getContract(swap_addr);
    if (contract != null && erc20 != null) {
      let orders = [];
      let decimals = await erc20.decimals().catch((err) => {console.error(err)})
      let order = await contract.allOrders(ethers.BigNumber.from(orderId)).catch((err) => {console.error(err)})
      if (typeof order !== "undefined" && typeof decimals !== "undefined") {
        let obj = {
          id:order.id.toNumber(),
          amount:ethers.utils.formatUnits(order.amount.toString(), decimals).toString(),
          address:order.owner,
          unlocktime:getdate(order.unlocktime.toNumber())
        }
        orders.push(obj)
      }

      layui.table.render({
        elem: '#findorder' //指定原始表格元素选择器（推荐id选择器）
        // ,height: 100 //容器高度
        ,skin: 'line' //行边框风格
        ,size: 'sm'
        ,cols: [[
          {field:'id', title:'ID', width:100},
          {field:'amount', title:'AMOUNT',width:100},
          {field:'address', title:'OWNER'},
          {field:'unlocktime', title:'RELEASE TIME'}
        ]],
        data:orders
      });
    }
  }


  useEffect(()=>{
    update_balance(account);
  },[account]);

  return (
      <div className="layui-fluid">
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md12">
            <div className="layui-card" style={{height:50,marginBottom:0}}>
              <div style={{height:'100%',paddingLeft:15,paddingRight:15,display:'flex',alignItems:'center'}}>
                <span className="font_bold_14" style={{flexShrink:0}}>{t('exchangeContent.walletaddress')}</span>
                <span className="value layui-hide-xs" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':account}</span>
                <span className="value layui-hide-sm layui-show-xs-inline" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':getSubStr(account)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md12">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.totalpoints')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span id="balance" className="value" style={{'fontSize':26}}>{totalpoints}</span>
                  </div>
                </div>
            </div>
          </div>
          {/* <div className="layui-col-md4">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.lockpoints')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span id="balance" className="value" style={{'fontSize':18}}>{lockedpoints}</span>
                  </div>
                </div>
            </div>
          </div>
          <div className="layui-col-md4">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.unlockpoints')}
                <button type="button" className="btn btn-primary" style={{float:'right',marginTop:5}} onClick={()=>{call_method("withdraw_order", [], setLoading_withdraw_order)}}>
                {
                  loading_withdraw_order === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                }
                {t('exchangeContent.withdraw_order')}
                </button>
              </div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span id="balance" className="value" style={{'fontSize':18}}>{unlockedpoints}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header">{t('exchangeContent.orderlist')}</div>
                <div className="layui-card-body">
                  <table className="layui-table" lay-size="sm" lay-skin="line" id="orderlist"></table>
                </div>
              </div>
          </div> */}
        </div>
        {/* <div className="layui-row layui-col-space15">
          <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header" style={{'display':'flex','alignItems':'center',paddingTop:5,paddingBottom:5}}>
                  <div style={{'display':'flex','alignItems':'center',width:'100%'}}>
                    <span style={{'width':80,flexShrink:0}}>{t('exchangeContent.findorder')}</span>
                    <input id="orderId_input" type="number" placeholder={t('exchangeContent.input_orderId')} autoComplete="off" className="layui-input layui-input-sm"></input>
                    <button type="button" className="btn btn-primary btn-block" style={{marginLeft:10,width:100,height:23}} onClick={onClickFind}>{t('exchangeContent.query')}</button>
                  </div>
                </div>
                <div className="layui-card-body">
                  <table className="layui-table" lay-size="sm" lay-skin="line" id="findorder"></table>
                </div>
              </div>
          </div>
        </div> */}
        {/* <div className={
            account !== null ? (admin_address.toLowerCase() === account.toLowerCase() ? 'layui-row layui-col-space15':'hide'):'hide'
          }>
          <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header" style={{'display':'flex','alignItems':'center'}}>{t('exchangeContent.method')}
                </div>
                <div className="layui-card-body">
                  <div className="layui-row layui-col-space10">
                    <div className="layui-col-md3">
                      <div className="context-flex-column border_context" style={{'height':'auto'}}>
                        <button type="button" className="btn btn-primary" onClick={()=>{
                            let params = check_inputs($("input.create_order_params"),loading_create_order);
                            if (params instanceof Array) {call_method("create_order", params, setLoading_create_order)}
                          }}>
                          {
                            loading_create_order === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                          }
                          {t('exchangeContent.create_order')}
                        </button>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.address')} :</span>
                          <input param_type="address" type="text" autoComplete="off" placeholder={t('exchangeContent.input_set_address')} className="layui-input create_order_params"></input>
                        </div>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.amount')} :</span>
                          <input param_type="amount" decimals="18" type="number" autoComplete="off" placeholder={t('exchangeContent.input_set_amount')} className="layui-input create_order_params"></input>
                        </div>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.unlocktime')} :</span>
                          <input param_type="uint" type="number" autoComplete="off" placeholder={t('exchangeContent.input_set_unlocktime')} className="layui-input create_order_params"></input>
                        </div>
                      </div>
                    </div>
                    <div className="layui-col-md3">
                      <div className="context-flex-column border_context" style={{'height':'auto'}}>
                        <button type="button" className="btn btn-primary" onClick={()=>{
                            let params = check_inputs($("input.set_update_order_params"),loading_update_order);
                            if (params instanceof Array) {call_method("update_order", params, setLoading_update_order)}
                          }}>
                          {
                            loading_update_order === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                          }
                          {t('exchangeContent.update_order')}</button>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.orderId')} :</span>
                          <input param_type="uint" type="number" autoComplete="off" placeholder={t('exchangeContent.input_orderId')} className="layui-input set_update_order_params"></input>
                        </div>
                        <div style={{'display':'none','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.orderId')} :</span>
                          <input value="0" readOnly param_type="uint" type="number" autoComplete="off" placeholder={t('exchangeContent.input_orderId')} className="layui-input set_update_order_params"></input>
                        </div>
                      </div>
                    </div>
                    <div className="layui-col-md3">
                      <div className="context-flex-column border_context" style={{'height':'auto'}}>
                        <button type="button" className="btn btn-primary" onClick={()=>{
                            let params = check_inputs($("input.withdrawERC20_params"),loading_withdrawERC20);
                            if (params instanceof Array) {call_method("withdrawERC20", params, setLoading_withdrawERC20)}
                          }}>
                          {
                            loading_withdrawERC20 === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                          }
                          {t('exchangeContent.withdrawERC20')}</button>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.address')} :</span>
                          <input param_type="address" type="text"  autoComplete="off" placeholder={t('exchangeContent.input_set_address')} className="layui-input withdrawERC20_params"></input>
                        </div>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.amount')} :</span>
                          <input param_type="amount" decimals="18" type="number"  autoComplete="off" placeholder={t('exchangeContent.input_set_amount')} className="layui-input withdrawERC20_params"></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div> */}
      </div>
  );
}

export default ExchangeContent;
