/* global layui $ */
import React, { useState, useEffect, useContext } from 'react';
import myWallet from './utils/mywallet';
import { ethers } from 'ethers';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';


function AirDrop() {
  const { t } = useTranslation('common');
  const { account, ecc_addr, airdrop_addr_1, trade_addr} = useContext(ExchangeContext);
  const [totalpoints, setTotalpoints] = useState("-");
  const [withdrawpoints, setWithdrawpoints] = useState("-");
  const [locklist, setlocklist] = useState([]);

  const getSubStr = str => {
    var subStr1 = str.substr(0, 6);
    var subStr2 = str.substr(str.length - 4, 4);
    var subStr = subStr1 + '...' + subStr2;
    return subStr;
  };

  const getdate = (timestamp) => {
    //美国东部时间
    if (timestamp === 0) return 0;
    let date = new Date(timestamp*1000 - (12*3600*1000));
    let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    return date2;


    //北京时间
    // if (timestamp === 0) return 0;
    // let date = new Date(timestamp*1000);
    // let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    // return date2;
  }

  const check_inputs = (inputs, _loading) => {
    if (account == null || _loading) {return}
    let params = [];
    for (let i = 0; i < inputs.length; i++) {
      let input = $(inputs.get(i));
      let placeholder_txt = input.attr("placeholder");
      if (input.val() === '') {
        layui.layer.alert(t(placeholder_txt));
        return
      }
      let param_type = input.attr("param_type");
      switch (param_type) {
        case "string":
        case "address":
          params.push(input.val())
          break;
        case "uint":
          params.push(ethers.BigNumber.from(input.val()))
          break;
        case "amount":
          {
            let decimals = input.attr("decimals");
            params.push(ethers.utils.parseUnits(input.val(), parseInt(decimals)))
          }
          break;
        case "boolean":
          {
            let _bool = parseInt(input.val())
            if (_bool === 0) {
              params.push(false)
            }
            else {
              params.push(true)
            }
          }
          break;
        case "array_address":
          {
            let array = JSON.parse(input.val());
            if (array instanceof Array) {
              params.push(array)
            }
            else {
              return
            }
          }
          break;
        case "array_uint":
          {
            let array = JSON.parse(input.val());
            if (array instanceof Array) {
              for (let i = 0; i< array.length; i++) {
                array[i] = ethers.BigNumber.from(array[i])
              }
              params.push(array)
            }
            else {
              return
            }
          }
          break;
        case "array_amount":
          {
            let decimals = input.attr("decimals");
            let array = JSON.parse(input.val());
            if (array instanceof Array) {
              for (let i = 0; i< array.length; i++) {
                array[i] = ethers.utils.parseUnits(array[i].toString(), parseInt(decimals))
              }
              params.push(array)
            }
            else {
              return
            }
          }
          break;
        default:
          break;
      }
    }
    return params
  }

  const call_method = async (method_name, params, _setloading) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let airdrop = myWallet.getContract(airdrop_addr_1);
    if (airdrop != null) {
      switch (method_name) {
        case "airDrop":
        {
          let gas = await airdrop.estimateGas.airDrop(params[0],params[1],params[2],params[3],params[4]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.airDrop(params[0],params[1],params[2],params[3],params[4], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                _setloading(false);
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              _setloading(true);
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "airDropBatch":
        {
          let gas = await airdrop.estimateGas.airDropBatch(params[0],params[1],params[2],params[3],params[4]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.airDropBatch(params[0],params[1],params[2],params[3],params[4], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                _setloading(false);
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              _setloading(true);
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const call_withdraw = async (method_name, params, element) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let airdrop = myWallet.getContract(airdrop_addr_1);
    if (airdrop != null) {
      switch (method_name) {
        case "withdrawLockToken":
        {
          let gas = await airdrop.estimateGas.withdrawLockToken(params).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.withdrawLockToken(params, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.show();
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "withdrawReleaseToken":
        {
          let gas = await airdrop.estimateGas.withdrawReleaseToken().catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await airdrop.withdrawReleaseToken({"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.show();
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const call_Trader = async (method_name, params, _setloading) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let trade = myWallet.getContract(trade_addr);
    if (trade != null) {
      switch (method_name) {
        case "setTrader":
        {
          let gas = await trade.estimateGas.setTrader(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await trade.setTrader(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                _setloading(false);
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              _setloading(true);
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "setTraderBatch":
        {
          let gas = await trade.estimateGas.setTraderBatch(params[0], params[1]).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await trade.setTraderBatch(params[0], params[1], {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                _setloading(false);
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              _setloading(true);
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt.status);
        clearInterval(ins);
      }
    }, 1000);
  };

  const update_balance = async _account => {
    if (_account == null) return;
    //_account = "0x5BF231dDE3985230E52C3dBE843cCB96922F969C"
    let ecctoken = myWallet.getContract(ecc_addr);
    let airdrop = myWallet.getContract(airdrop_addr_1);
    if (airdrop != null && ecctoken != null) {
      let balance = await ecctoken.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance !== "undefined") {
        setTotalpoints(ethers.utils.formatUnits(balance.toString(), 18).toString())
      }
      
      let _withdrawpoints = await airdrop["show_releaseMap()"]().catch(err => {console.error(err)});
      if (typeof _withdrawpoints !== "undefined") {
        setWithdrawpoints(ethers.utils.formatUnits(_withdrawpoints.toString(), 18).toString())
      }

      let lockIds = await airdrop["show_lockTimestampMap()"]().catch(err => {console.error(err)});
      if (typeof lockIds !== "undefined") {
        let orders = [];
        for (let i = 0; i < lockIds.length; i++) {
          let time = lockIds[i].toNumber();
          let lockinfo = await airdrop["show_LockInfo(uint256)"](time).catch(err => {console.error(err)});
          if (typeof lockinfo !== "undefined" ) {
            if (lockinfo.active) {
              let released = await airdrop["show_release(uint256)"](time).catch(err => {console.error(err)});
              let obj = {
                id:time,
                begin_release:getdate(lockinfo.begin_release.toNumber()),
                end_release:getdate(lockinfo.end_release.toNumber()),
                balance:ethers.utils.formatUnits(lockinfo.balance.toString(), 18).toString(),
                released:ethers.utils.formatUnits(released.toString(), 18).toString()
              }
              orders.push(obj);
            }
          }
        }
        setlocklist(orders);
      }
    }
  };

  

  useEffect(() => {
    update_balance(account);
  }, [account]);

  return (
    <div className="layui-fluid">
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md12">
            <div className="layui-card" style={{height:50,marginBottom:0}}>
              <div style={{height:'100%',paddingLeft:15,paddingRight:15,display:'flex',alignItems:'center'}}>
                <span className="font_bold_14" style={{flexShrink:0}}>{t('exchangeContent.walletaddress')}</span>
                <span className="value layui-hide-xs" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':account}</span>
                <span className="value layui-hide-sm layui-show-xs-inline" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':getSubStr(account)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="layui-row layui-col-space15">
          <div className="layui-col-md6">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.totalpoints')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span className="value" style={{'fontSize':18}}>{totalpoints}</span>
                  </div>
                </div>
            </div>
          </div>
          <div className="layui-col-md6" id="extractpoints">
            <div className="layui-card">
              <div className="layui-card-header context-flex-space-between">{t('exchangeContent.extractpoints')}
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                    let element = $("#extractpoints i.layui-icon-loading");
                    call_withdraw("withdrawReleaseToken", null, element)
                  }}>
                  <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                  {t('exchangeContent.withdraw')}
                </button>
              </div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span className="value" style={{'fontSize':18}}>{withdrawpoints}</span>
                  </div>
                </div>
            </div>
          </div>
          <div className="layui-col-md12">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.locklist')}</div>
              <div className="layui-card-body">
                <div className="layui-row layui-col-space15">
                {
                  locklist.map((item) => {
                    return <div className="layui-col-md4" id={item.id}>
                            <div className="layui-card">
                              <div className="layui-card-header">Id：{item.id}</div>
                                <div className="layui-card-body" style={{padding:'5px 5px'}}>
                                  <div className="context-flex-column" style={{height:'auto'}}>
                                    <div className='context-flex-space-between'>
                                      <span className="value" style={{'fontSize':16}}>{t("exchangeContent.start_release")} :</span>
                                      <span className="value" style={{'fontSize':16}}>{item.begin_release}</span>
                                    </div>
                                    <div className='context-flex-space-between'>
                                      <span className="value" style={{'fontSize':16}}>{t("exchangeContent.end_release")} :</span>
                                      <span className="value" style={{'fontSize':16}}>{item.end_release}</span>
                                    </div>
                                    <div className='context-flex-space-between'>
                                      <span className="value" style={{'fontSize':16}}>{t("exchangeContent.locked_ECC")} :</span>
                                      <span className="value" style={{'fontSize':16}}>{item.balance}</span>
                                    </div>
                                    <div className='context-flex-space-between'>
                                      <span className="value" style={{'fontSize':16}}>{t("exchangeContent.released_ECC")} :</span>
                                      <span className="value" style={{'fontSize':16, color:'red'}}>{item.released}</span>
                                    </div>
                                    <button type="button" className="btn btn-primary" style={{marginTop:20}} onClick={()=>{
                                        let element = $("#" + item.id + " i.layui-icon-loading");
                                        call_withdraw("withdrawLockToken", item.id, element)
                                      }}>
                                      <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                                      {t('exchangeContent.withdraw')}
                                    </button>
                                  </div>
                                </div>
                            </div>
                          </div>
                  })
                }
                </div>
              </div>
            </div>
          </div>
        </div>  
    </div>
  );
}

export default AirDrop;
