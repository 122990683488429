/* global layui $ */
import React, { useState, useEffect, useContext } from 'react';
import myWallet from './utils/mywallet';
import { ethers } from 'ethers';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';

function ExchangeSwap_c() {
  const { t } = useTranslation('common');
  const { account, ecc_addr, swap_addr, exchange_c} = useContext(ExchangeContext);
  const [balance_ecc, setBalance_ecc] = useState('-');
  const [balance_eth, setBalance_eth] = useState('-');
  const [exchangeRate, setExchangeRate] = useState('-');
  const [loading_swap, setLoading_swap] = useState(false);

  const getSubStr = str => {
    var subStr1 = str.substr(0, 6);
    var subStr2 = str.substr(str.length - 4, 4);
    var subStr = subStr1 + '...' + subStr2;
    return subStr;
  };

  const _do_method = async (contract, method_name, params) => {
    let estimateGas_fun = contract.estimateGas[method_name];
    let gas = await estimateGas_fun.apply(this, params).catch(err => {
      console.error(err);
    });
    if (typeof gas !== 'undefined') {
      let gaslimit = Math.round(gas.toNumber() * 2);
      let fun = contract[method_name];
      params.push({ gasLimit: gaslimit });
      let tx = await fun.apply(this, params).catch(err => {
        console.error(err);
      });
      return tx;
    }
  };

  const call_method = async (method_name, params, _setloading) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let swap = myWallet.getContract(swap_addr);
    if (swap != null) {
      //estimateGas
      let amount = params[0];
      let balance = myWallet.getBalance();
      // let res = await usdt.allowance(account, swap_addr).catch((err) => {console.error(err)});
      if (balance !== null) {
        if (balance.gte(amount)) {
          // >=
          let tx = await _do_method(swap, method_name, params);
          let success = method_name + ' ' + t('exchangeContent.success');
          let fail = method_name + ' ' + t('exchangeContent.fail');
          if (typeof tx !== 'undefined') {
            addTxcheck(tx.hash, function(status) {
              if (status === 1) {
                layui.layer.msg(success);
                update_balance(account);
              } else {
                layui.layer.msg(fail);
              }
              _setloading(false);
            });
            _setloading(true);
          } else {
            layui.layer.msg(fail);
            return;
          }
        }
      }
    }
  };

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt.status);
        clearInterval(ins);
      }
    }, 1000);
  };

  const update_balance = async _account => {
    if (_account == null) return;
    let ecc = myWallet.getContract(ecc_addr);
    let swap = myWallet.getContract(swap_addr);
    if (ecc != null && swap != null) {
      let bb = await ecc.balanceOf(_account).catch(err => {
        console.error(err);
      });
      if (typeof bb !== 'undefined') {
        setBalance_ecc(ethers.utils.formatUnits(bb.toString(), 18).toString());
      }

      let bb2 = await myWallet.getBalance(_account).catch(err => {
        console.error(err);
      });
      if (typeof bb2 !== 'undefined') {
        setBalance_eth(
          parseFloat(
            ethers.utils.formatUnits(bb2.toString(), 18).toString()
          ).toFixed(4)
        );
      }

      let _exchangeRate = await swap.exchangeRate().catch(err => {
        console.error(err);
      });
      if (typeof _exchangeRate !== 'undefined') {
        _exchangeRate = _exchangeRate.div(100);
        setExchangeRate(_exchangeRate.toString());
      }
    }
  };

  useEffect(() => {
    update_balance(account);
    layui.use('form', function() {
      var form = layui.form;
      form.on('submit(formswap)', function(data) {
        if (account !== null && !loading_swap) {
          console.log(data)
          //params.push(ethers.utils.parseUnits(data.field.amount, 18))
          //params.push(data.field.address)

          //call_method("swap", params, setLoading_swap);
          setLoading_swap(true)
          myWallet
            .sendTransaction(account, exchange_c, data.field.amount, "")
            .then(function(res) {
              setLoading_swap(false)
            })
            .catch((error) => {
              setLoading_swap(false)
              console.log(error)
            })
        }

        return false;
      });
    });
  }, [account]);

  return (
    <div className="layui-fluid">
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card" style={{ height: 50, marginBottom: 0 }}>
            <div
              style={{
                height: '100%',
                paddingLeft: 15,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span className="font_bold_14" style={{ flexShrink: 0 }}>
                {t('exchangeContent.walletaddress')}
              </span>
              <span
                className="value layui-hide-xs"
                style={{
                  textAlign: 'left',
                  wordBreak: 'break-all',
                  marginLeft: 10
                }}
              >
                {account == null ? '-' : account}
              </span>
              <span
                className="value layui-hide-sm layui-show-xs-inline"
                style={{
                  textAlign: 'left',
                  wordBreak: 'break-all',
                  marginLeft: 10
                }}
              >
                {account == null ? '-' : getSubStr(account)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md6">
          <div className="layui-card">
            <div className="layui-card-header">{t('exchangeContent.totalpoints')}</div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span className="value" style={{'fontSize':26}}>{balance_ecc}</span>
                </div>
              </div>
          </div>
        </div>
        <div className="layui-col-md6">
          <div className="layui-card">
            <div className="layui-card-header">{t('exchangeContent.usdtbalance')}</div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span className="value" style={{'fontSize':26}}>{balance_eth}</span>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card">
            <div
              className="layui-card-header"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {t('exchangeContent.swapInfo_c')}
            </div>
            <div className="layui-card-body">
              <form
                className="layui-form"
                action=""
                lay-filter="component-form-element"
              >
                <div className="layui-row layui-col-space10 layui-form-item">
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.swap_ratio')}：
                    </label>
                    <label
                      className="layui-form-label"
                      style={{
                        textAlign: 'right',
                        width: 'auto',
                        paddingLeft: 8
                      }}
                    >
                    1 ETH = 4600 ECC
                      {/* {t('exchangeContent.waite_ratio')} */}
                    </label>
                  </div>
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.swap_points')}：
                    </label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150 }}
                    >
                      <input
                        type="number"
                        name="amount"
                        lay-verify="required"
                        placeholder={t('exchangeContent.input_set_amount')}
                        autoComplete="off"
                        className="layui-input"
                      />
                    </div>
                  </div>
                  {/* <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.swap_address')}：
                    </label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150 }}
                    >
                      <input
                        defaultValue={account}
                        type="text"
                        name="address"
                        lay-verify="required"
                        placeholder={t('exchangeContent.input_set_address')}
                        autoComplete="off"
                        className="layui-input"
                      />
                    </div>
                  </div> */}
                  {/* <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.memo')}：
                    </label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150 }}
                    >
                      <input
                        type="text"
                        name="memo"
                        placeholder={t('exchangeContent.input_memo')}
                        autoComplete="off"
                        className="layui-input"
                      />
                    </div>
                  </div>
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    ></label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150, color: 'red' }}
                    >
                      {t('exchangeContent.waite_c')}
                    </div>
                  </div> */}
                </div>
                <div className="layui-form-item">
                    <div className="layui-input-block" style={{marginLeft:150}}>
                      <button className="btn btn-primary" type="button" lay-submit="" lay-filter="formswap" style={{marginRight:10}}>
                      {
                        loading_swap === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                      }
                      {t('exchangeContent.submit')}</button>
                      <button type="reset" className="btn btn-outline-primary">{t('exchangeContent.reset')}</button>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExchangeSwap_c;
