import { ethers } from "ethers";

class MyWallet {

    constructor(){
        this.contracts = {}
        this.web3_provider = null
        this.account_callback = null;
    }

    handleAccountsChanged(accounts){
        if (accounts.length === 0) {
            // Wallet is locked or the user has not connected any accounts
            this.account = null;
            console.log('Please connect your wallet (Metamask, TrustWallet, etc).');
            if (this.account_callback != null) {
                this.account_callback(this.account);
            }
        } else {
            this.account = accounts[0];
            if (this.account_callback != null) {
                this.account_callback( this.account);
            }
        }
    }

    addContract (address, abi) {
        if (this.web3_provider != null) {
            if (this.contracts[address] === undefined) {
               // let 
                this.contracts[address] = new ethers.Contract(address, abi, this.web3_provider);
            }
        }
    }

    getBalance (address) {
        if (this.web3_provider != null) {
            return this.web3_provider.getBalance(address);
        }
    }

    getContract (address) {
        if (this.web3_provider != null) {
            if (this.contracts[address] !== undefined) {
                let contract_signer = this.contracts[address].connect(this.web3_provider.getSigner())
                return contract_signer
            }
        }
        return null;
    }

    connect () {
        if (this.web3_provider != null) {
            return this.web3_provider.send('eth_requestAccounts').catch((err) => {console.error(err)});
        }
        return null;
    }

    async getTransactionReceipt(tx) {
        let receipt = await this.web3_provider.getTransactionReceipt(tx).catch((err) => {console.error(err)});
        return receipt
    }

    async sendTransaction (_from, _to, _value, _msg) {
        if (this.web3_provider != null) {
            let _data = null;
            if (_msg.length > 0) {
                _data = ethers.utils.hashMessage(_msg);
            }

            const params = [{
                from: _from,
                to: _to,
                value: ethers.utils.parseUnits(_value, 'ether').toHexString()
            }];

            if (_data != null) {
                params[0].data = _data
            }
            return this.web3_provider.send('eth_sendTransaction', params);
        }
    }

    init(account_callback) {
        if (window.ethereum !== undefined) {
            this.account_callback = account_callback
            window.ethereum.on('accountsChanged', (accounts) => {
                this.handleAccountsChanged(accounts)
            });
            this.web3_provider = new ethers.providers.Web3Provider(window.ethereum);
            return true;
        }
        else {
            this.web3_provider = null;
            return false;
        }
    }



    // async sendTransaction_ERC20(erc20_address, to, value) {
    //     if (this.web3_provider != null && this.account != null && this.erc20abi != null) {
    //         if (typeof this.contracts[erc20_address] == "undefined") {
    //             this.contracts[erc20_address] = new ethers.Contract(erc20_address, this.erc20abi, this.web3_provider);
    //         }

    //         let contract_signer = this.contracts[erc20_address].connect(this.web3_provider.getSigner())
    //         let tx = await contract_signer.transfer(to, ethers.utils.parseUnits(value, 'ether').toHexString()).catch((err) => {console.error(err)});
    //         return tx
    //     }
    // }
}

var myWallet = new MyWallet()

export default myWallet;