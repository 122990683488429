/* global layui $ ethers */
import React from 'react';
import { useTranslation } from 'react-i18next';

function About() {
  const { t, i18n } = useTranslation('common');

  const getdetail = (lng) => {
    switch (lng) {
      case 'cn':
        return <p style={{color:'black', fontSize:15}}>ECC--BSC链细胞工程项目是由新加坡（ECC）基金会投资，主要应用于Epocha cell生物科技公司项目融资方案和Future全球细胞生态系统补贴，致力于创造一个去中心化平台来建立患者的信息管理中心和折扣优惠平台，用于管理集成治疗、引流，追踪，信息处理为一体的生态系统，Epochal和Future的生态以ECC作为流通媒介，来实现全球细胞治疗普及和全球细胞工程应用的资金支持。<br/>
        ECC作为干细胞全球化的起点，将颠覆细胞治疗、健康投资、跨国消费等多个领域；<br/>
        细胞NFT+质押挖矿，赋能用户投资属性，实现真正的健康投资；<br/>
        细胞治疗+资源整合+商家补贴=开创全球化干细胞元宇宙；<br/>
        ECC用去中心化的区块链技术作为基础（BSC），结合NFT用于跨国际可视化交易，去中心计划金融（DIFI）的质押挖矿模式，建立了一套可用于补助全球细胞工程的经济模型。</p>
      case 'en':
        return <p style={{color:'black', fontSize:15}}>The ECC--BSC chain cell engineering project is invested by the Singapore (ECC) Foundation, mainly used in the project financing scheme of Epocha cell biotechnology company and the Future global cell ecosystem subsidy, and is committed to creating a decentralized platform to establish patient information The management center and discount platform are used to manage an ecosystem that integrates treatment, drainage, tracking, and information processing. The ecology of Epochal and Future uses ECC as a circulation medium to realize the popularization of global cell therapy and the financial support for the application of global cell engineering.<br/>
        As the starting point of stem cell globalization, ECC will subvert many fields such as cell therapy, health investment, and transnational consumption;<br/>
        Cell NFT + pledge mining, empowers users to invest in attributes, and realizes real healthy investment;<br/>
        Cell therapy + resource integration + business subsidies = creating a global stem cell metaverse;<br/>
        ECC uses decentralized blockchain technology as the foundation (BSC), combined with NFT for cross-international visualized transactions, and the pledge mining model of Decentralized Plan Finance (DIFI), to establish an economy that can be used to subsidize global cellular engineering Model.
        </p>
      case 'jp':
        return <p style={{color:'black', fontSize:15}}>ECC--BSCチェーンセルエンジニアリングプロジェクトは、シンガポール（ECC）財団によって投資され、主にEpochaセルバイオテクノロジー企業のプロジェクト資金調達スキームと将来のグローバルセルエコシステム補助金で使用され、患者を確立するための分散型プラットフォームの作成に取り組んでいます情報管理センターと割引プラットフォームは、治療、排液、追跡、情報処理を統合するエコシステムを管理するために使用されます。EpochalandFutureのエコロジーは、ECCを循環媒体として使用して、グローバルな細胞治療の普及とグローバルセルエンジニアリングの応用。<br/>
        幹細胞のグローバル化の出発点として、ECCは細胞治療、医療投資、国境を越えた消費などの多くの分野を破壊します。<br/>
        Cell NFT +はマイニングを誓約し、ユーザーが属性に投資できるようにし、真に健全な投資を実現します。<br/>
        細胞治療+リソース統合+ビジネス補助金=グローバルな幹細胞メタバースの作成;<br/>
        ECCは、分散型ブロックチェーンテクノロジーを基盤（BSC）として使用し、国際的な視覚化トランザクション用のNFTと、分散型プランファイナンス（DIFI）の誓約マイニングモデルを組み合わせて、グローバルセルラーエンジニアリングモデルへの助成に使用できる経済を確立します。</p>
      case 'sa': //阿拉伯
        return <p style={{color:'black', fontSize:15, textAlign:'right'}}>يتم استثمار مشروع هندسة الخلايا المتسلسلة ECC - BSC من قبل مؤسسة سنغافورة (ECC) ، وهي تستخدم بشكل أساسي في مخطط تمويل المشروع لشركة Epocha للتكنولوجيا الحيوية لخلايا Epocha ودعم النظام الإيكولوجي العالمي للخلايا المستقبلية ، وهي ملتزمة بإنشاء منصة لامركزية لتأسيس المريض المعلومات يتم استخدام مركز الإدارة ومنصة الخصم لإدارة نظام بيئي يدمج العلاج والتصريف والتتبع ومعالجة المعلومات. تستخدم بيئة Epochal و Future ECC كوسيلة تداول لتحقيق تعميم العلاج بالخلايا العالمي والدعم المالي لـ تطبيق هندسة الخلايا العالمية.<br/>
        كنقطة انطلاق لعولمة الخلايا الجذعية ، ستقوم ECC بإفساد العديد من المجالات مثل العلاج بالخلايا ، والاستثمار الصحي ، والاستهلاك عبر الوطني ؛
        <br/>تعهد شركة Cell NFT + بالتعدين ، وتمكين المستخدمين من الاستثمار في السمات ، وتحقيق استثمار صحي حقيقي ؛
        <br/>العلاج بالخلايا + تكامل الموارد + الإعانات التجارية = إنشاء metaverse للخلايا الجذعية العالمية ؛
        <br/>تستخدم ECC تقنية blockchain اللامركزية كأساس (BSC) ، جنبًا إلى جنب مع NFT للمعاملات المرئية عبر الدولية ، ونموذج تعدين التعهد الخاص بتمويل الخطة اللامركزية (DIFI) لإنشاء اقتصاد يمكن استخدامه لدعم نموذج الهندسة الخلوية العالمي.</p>
      case 'es': //西班牙
        return <p style={{color:'black', fontSize:15}}>El proyecto de ingeniería de células de cadena ECC--BSC es invertido por la Fundación de Singapur (ECC), utilizado principalmente en el esquema de financiamiento de proyectos de la compañía de biotecnología celular Epocha y el subsidio del ecosistema celular global Future, y está comprometido a crear una plataforma descentralizada para establecer paciente información El centro de gestión y la plataforma de descuento se utilizan para gestionar un ecosistema que integra el tratamiento, el drenaje, el seguimiento y el procesamiento de la información La ecología de Epochal y Future utiliza ECC como medio de circulación para realizar la popularización de la terapia celular global y el apoyo financiero para la aplicación de la ingeniería celular global.
        <br/>Como punto de partida de la globalización de las células madre, la ECC subvertirá muchos campos, como la terapia celular, la inversión en salud y el consumo transnacional;
        <br/>Cell NFT + minería de compromiso, permite a los usuarios invertir en atributos y realiza una inversión real y saludable;
        <br/>Terapia celular + integración de recursos + subsidios comerciales = creación de un metaverso global de células madre;
        <br/>ECC utiliza la tecnología de cadena de bloques descentralizada como base (BSC), combinada con NFT para transacciones visualizadas internacionales cruzadas, y el modelo de minería de compromiso de Financiamiento del plan descentralizado (DIFI) para establecer una economía que puede usarse para subsidiar el modelo de ingeniería celular global.</p>
      case 'fr': //法国
        return <p style={{color:'black', fontSize:15}}>Le projet d'ingénierie de cellules en chaîne ECC--BSC est investi par la Fondation de Singapour (ECC), principalement utilisé dans le cadre du programme de financement de projet de la société de biotechnologie cellulaire Epocha et de la subvention Future global cell ecosystem, et s'engage à créer une plate-forme décentralisée pour établir des patients information Le centre de gestion et la plateforme de remise permettent de gérer un écosystème qui intègre traitement, drainage, suivi et traitement de l'information L'écologie d'Epochal et Future utilise l'ECC comme support de circulation pour réaliser la vulgarisation de la thérapie cellulaire globale et le soutien financier pour l'application de l'ingénierie cellulaire globale.
        <br/>En tant que point de départ de la mondialisation des cellules souches, l'ECC subvertira de nombreux domaines tels que la thérapie cellulaire, l'investissement dans la santé et la consommation transnationale ;
        <br/>Cell NFT + Page Mining, permet aux utilisateurs d'investir dans des attributs et réalise un véritable investissement sain ;
        <br/>Thérapie cellulaire + intégration des ressources + subventions aux entreprises = création d'un métaverse mondial des cellules souches ;
        <br/>ECC utilise la technologie blockchain décentralisée comme base (BSC), combinée avec NFT pour les transactions visualisées inter-internationales, et le modèle d'extraction de promesses de Financement de Plan Décentralisé (DIFI) pour établir une économie qui peut être utilisée pour subventionner le modèle mondial d'ingénierie cellulaire.</p>
      case 'ru': //俄罗斯
        return <p style={{color:'black', fontSize:15}}>Проект ECC--BSC по разработке цепных клеток инвестируется Фондом Сингапура (ECC), который в основном используется в схеме финансирования проекта клеточной биотехнологической компании Epocha и субсидии на глобальную клеточную экосистему Future, и направлен на создание децентрализованной платформы для создания пациентов. информация Центр управления и дисконтная платформа используются для управления экосистемой, объединяющей лечение, дренаж, отслеживание и обработку информации. применение глобальной клеточной инженерии.
        <br/>Будучи отправной точкой глобализации стволовых клеток, ECC разрушит многие области, такие как клеточная терапия, инвестиции в здравоохранение и транснациональное потребление;
        <br/>Cell NFT + майнинг залога, дает пользователям возможность инвестировать в атрибуты и реализует настоящие здоровые инвестиции;
        <br/>Клеточная терапия + интеграция ресурсов + бизнес-субсидии = создание глобальной метавселенной стволовых клеток;
        <br/>ECC использует децентрализованную технологию блокчейна в качестве основы (BSC) в сочетании с NFT для кросс-международных визуализированных транзакций и модель залогового майнинга децентрализованного планового финансирования (DIFI) для создания экономики, которую можно использовать для субсидирования глобальной модели сотовой инженерии.</p>
      case 'de': //德国
        return <p style={{color:'black', fontSize:15}}>Das ECC--BSC-Chain-Cell-Engineering-Projekt wird von der Singapore (ECC) Foundation investiert, die hauptsächlich im Projektfinanzierungsprogramm des Zellbiotechnologieunternehmens Epocha und der Subvention für das globale Zellökosystem Future verwendet wird, und hat sich der Schaffung einer dezentralen Plattform zur Etablierung von Patienten verschrieben Informationen Das Verwaltungszentrum und die Rabattplattform werden verwendet, um ein Ökosystem zu verwalten, das Behandlung, Entwässerung, Verfolgung und Informationsverarbeitung integriert. Die Ökologie von Epochal and Future nutzt ECC als Verbreitungsmedium, um die Popularisierung der globalen Zelltherapie und die finanzielle Unterstützung dafür zu realisieren die Anwendung des globalen Zell-Engineerings.
        <br/>Als Ausgangspunkt der Stammzellglobalisierung wird ECC viele Bereiche wie Zelltherapie, Gesundheitsinvestitionen und transnationalen Konsum untergraben;
        <br/>Cell NFT + Pledge Mining, ermöglicht Benutzern, in Attribute zu investieren, und realisiert eine wirklich gesunde Investition;
        <br/>Zelltherapie + Ressourcenintegration + Unternehmenssubventionen = Schaffung einer globalen Metaverse für Stammzellen;
        <br/>ECC verwendet die dezentrale Blockchain-Technologie als Grundlage (BSC), kombiniert mit NFT für grenzüberschreitende, visualisierte Transaktionen, und das Pledge-Mining-Modell von Decentralized Plan Finance (DIFI), um eine Wirtschaft aufzubauen, die zur Subventionierung des globalen Mobilfunk-Engineering-Modells verwendet werden kann.</p>
      case 'pt': //葡萄牙
        return <p style={{color:'black', fontSize:15}}>O projeto de engenharia de células de cadeia ECC--BSC é investido pela Fundação Cingapura (ECC), usado principalmente no esquema de financiamento de projetos da empresa de biotecnologia de células Epocha e no subsídio do ecossistema global de células Future, e está comprometido em criar uma plataforma descentralizada para estabelecer informações O centro de gestão e a plataforma de descontos são utilizados para gerenciar um ecossistema que integra tratamento, drenagem, rastreamento e processamento de informações. A ecologia da Epochal e do Futuro utiliza a ECC como meio de circulação para realizar a popularização da terapia celular global e o apoio financeiro para a aplicação da engenharia celular global.
        <br/>Como ponto de partida da globalização das células-tronco, a ECC irá subverter muitos campos, como terapia celular, investimento em saúde e consumo transnacional;
        <br/>Cell NFT + mineração de promessas, capacita os usuários a investir em atributos e realiza um investimento real e saudável;
        <br/>Terapia celular + integração de recursos + subsídios empresariais = criação de um metaverso global de células-tronco;
        <br/>A ECC usa a tecnologia blockchain descentralizada como base (BSC), combinada com NFT para transações visualizadas internacionais, e o modelo de mineração de promessas de Decentralized Plan Finance (DIFI) para estabelecer uma economia que pode ser usada para subsidiar o modelo global de engenharia celular.</p>
      case 'it': //意大利
        return <p style={{color:'black', fontSize:15}}>Il progetto di ingegneria cellulare a catena ECC--BSC è investito dalla Fondazione Singapore (ECC), utilizzata principalmente nello schema di finanziamento del progetto della società di biotecnologia cellulare Epocha e nel sussidio per l'ecosistema cellulare globale Future, e si impegna a creare una piattaforma decentralizzata per stabilire pazienti informazioni Il centro di gestione e la piattaforma di sconti vengono utilizzati per gestire un ecosistema che integra il trattamento, il drenaggio, il monitoraggio e l'elaborazione delle informazioni. L'ecologia di Epochal e Future utilizza l'ECC come mezzo di circolazione per realizzare la divulgazione della terapia cellulare globale e il sostegno finanziario per l'applicazione dell'ingegneria cellulare globale.
        <br/>Come punto di partenza della globalizzazione delle cellule staminali, l'ECC sovvertirà molti campi come la terapia cellulare, gli investimenti sanitari e il consumo transnazionale;
        <br/>Cell NFT + pledge mining, consente agli utenti di investire in attributi e realizza investimenti davvero salutari;
        <br/>Terapia cellulare + integrazione delle risorse + sussidi alle imprese = creazione di un metaverso globale delle cellule staminali;
        <br/>ECC utilizza la tecnologia blockchain decentralizzata come base (BSC), combinata con NFT per transazioni visualizzate internazionali e il modello di pegno mining di Decentralized Plan Finance (DIFI) per stabilire un'economia che può essere utilizzata per sovvenzionare il modello globale di ingegneria cellulare.</p>
      case 'bd': //孟加拉
        return <p style={{color:'black', fontSize:15}}>ECC--BSC চেইন সেল ইঞ্জিনিয়ারিং প্রকল্পটি সিঙ্গাপুর (ECC) ফাউন্ডেশন দ্বারা বিনিয়োগ করা হয়, যা মূলত ইপোচা সেল বায়োটেকনোলজি কোম্পানির প্রকল্প অর্থায়ন প্রকল্পে এবং ভবিষ্যত গ্লোবাল সেল ইকোসিস্টেম ভর্তুকিতে ব্যবহৃত হয় এবং রোগীকে প্রতিষ্ঠিত করার জন্য একটি বিকেন্দ্রীভূত প্ল্যাটফর্ম তৈরি করতে প্রতিশ্রুতিবদ্ধ। তথ্য ব্যবস্থাপনা কেন্দ্র এবং ডিসকাউন্ট প্ল্যাটফর্ম একটি ইকোসিস্টেম পরিচালনা করতে ব্যবহৃত হয় যা চিকিত্সা, নিষ্কাশন, ট্র্যাকিং এবং তথ্য প্রক্রিয়াকরণকে একীভূত করে। ইপোচাল এবং ভবিষ্যতের বাস্তুসংস্থান বিশ্বব্যাপী সেল থেরাপির জনপ্রিয়করণ এবং এর জন্য আর্থিক সহায়তা উপলব্ধি করার জন্য একটি প্রচলন মাধ্যম হিসাবে ECC ব্যবহার করে। গ্লোবাল সেল ইঞ্জিনিয়ারিং এর প্রয়োগ।
        <br/>স্টেম সেল বিশ্বায়নের সূচনা বিন্দু হিসাবে, ECC সেল থেরাপি, স্বাস্থ্য বিনিয়োগ, এবং ট্রান্সন্যাশনাল কনজাম্পশনের মতো অনেক ক্ষেত্রকে ধ্বংস করবে;
        <br/>সেল NFT + প্রতিশ্রুতি মাইনিং, ব্যবহারকারীদের বৈশিষ্ট্যগুলিতে বিনিয়োগ করার ক্ষমতা দেয় এবং প্রকৃত সুস্থ বিনিয়োগ উপলব্ধি করে;
        <br/>সেল থেরাপি + রিসোর্স ইন্টিগ্রেশন + ব্যবসায়িক ভর্তুকি = একটি বিশ্বব্যাপী স্টেম সেল মেটাভার্স তৈরি করা;
        <br/>ECC বিকেন্দ্রীভূত ব্লকচেইন প্রযুক্তিকে ভিত্তি হিসেবে ব্যবহার করে (BSC), ক্রস-আন্তর্জাতিক ভিজ্যুয়ালাইজড লেনদেনের জন্য NFT এর সাথে মিলিত, এবং একটি অর্থনীতি প্রতিষ্ঠা করতে বিকেন্দ্রীভূত প্ল্যান ফাইন্যান্স (DIFI) এর প্রতিশ্রুতি মাইনিং মডেল যা বৈশ্বিক সেলুলার ইঞ্জিনিয়ারিং মডেলকে ভর্তুকি দেওয়ার জন্য ব্যবহার করা যেতে পারে।</p>
      default:
      return
    }
  }

  const getdetail2 = (lng) => {
    switch (lng) {
      case 'cn':
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>
        币安链主网<br/>
        网络名称：Binance Smart Chain<br/>
        RPC URL：https://bsc-dataseed1.defibit.io/<br/>
        智能链ID：56<br/>
        符号：BNB<br/>
        区块浏览器URL：https://bscscan.com<br/><br/>
        
        合约地址<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F</p>
      case 'en':
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>
        Binance Chain Mainnet<br/>
        Network Name：Binance Smart Chain<br/>
        RPC URL：https://bsc-dataseed1.defibit.io/<br/>
        ChainID：56<br/>
        Symbol：BNB<br/>
        Block Explorer URL：https://bscscan.com<br/><br/>
        
        Contract Address:<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F
        </p>
      case 'jp':
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>
        バイナンスチェーンメインネット<br/>
        ネットワーク名：Binance Smart Chain<br/>
        RPC URL：https://bsc-dataseed1.defibit.io/<br/>
        スマートチェーンID：56<br/>
        記号：BNB<br/>
        ブラウザのURLをブロックする：https://bscscan.com<br/><br/>
        
        契約住所:<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F</p>
      case 'sa': //阿拉伯
        return <p style={{color:'black', fontSize:12, textAlign:'right', textTransform:'initial'}}>
        سلسلة Binance Mainnet<br/>
        اسم الشبكة: Binance Smart Chain<br/>
        عنوان URL لـ RPC: https://bsc-dataseed1.defibit.io/<br/>
        معرف السلسلة الذكية: 56<br/>
        الرمز: BNB<br/>
        منع عنوان URL للمستعرض: https://bscscan.com<br/><br/>
        
        :عنوان العقد<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F</p>
      case 'es': //西班牙
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>El proyecto de ingeniería de células de cadena ECC--BSC es invertido por la Fundación de Singapur (ECC), utilizado principalmente en el esquema de financiamiento de proyectos de la compañía de biotecnología celular Epocha y el subsidio del ecosistema celular global Future, y está comprometido a crear una plataforma descentralizada para establecer paciente información El centro de gestión y la plataforma de descuento se utilizan para gestionar un ecosistema que integra el tratamiento, el drenaje, el seguimiento y el procesamiento de la información La ecología de Epochal y Future utiliza ECC como medio de circulación para realizar la popularización de la terapia celular global y el apoyo financiero para la aplicación de la ingeniería celular global.
        <br/>Como punto de partida de la globalización de las células madre, la ECC subvertirá muchos campos, como la terapia celular, la inversión en salud y el consumo transnacional;
        <br/>Cell NFT + minería de compromiso, permite a los usuarios invertir en atributos y realiza una inversión real y saludable;
        <br/>Terapia celular + integración de recursos + subsidios comerciales = creación de un metaverso global de células madre;
        <br/>ECC utiliza la tecnología de cadena de bloques descentralizada como base (BSC), combinada con NFT para transacciones visualizadas internacionales cruzadas, y el modelo de minería de compromiso de Financiamiento del plan descentralizado (DIFI) para establecer una economía que puede usarse para subsidiar el modelo de ingeniería celular global.</p>
      case 'fr': //法国
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>
        Réseau principal de la chaîne Binance<br/>
        Nom du réseau : Binance Smart Chain<br/>
        URL RPC : https://bsc-dataseed1.defibit.io/<br/>
        ID de chaîne intelligente : 56<br/>
        Symbole : BNB<br/>
        Bloquer l'URL du navigateur : https://bscscan.com<br/><br/>
        
        adresse du contrat:<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F</p>
      case 'ru': //俄罗斯
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>Проект ECC--BSC по разработке цепных клеток инвестируется Фондом Сингапура (ECC), который в основном используется в схеме финансирования проекта клеточной биотехнологической компании Epocha и субсидии на глобальную клеточную экосистему Future, и направлен на создание децентрализованной платформы для создания пациентов. информация Центр управления и дисконтная платформа используются для управления экосистемой, объединяющей лечение, дренаж, отслеживание и обработку информации. применение глобальной клеточной инженерии.
        <br/>Будучи отправной точкой глобализации стволовых клеток, ECC разрушит многие области, такие как клеточная терапия, инвестиции в здравоохранение и транснациональное потребление;
        <br/>Cell NFT + майнинг залога, дает пользователям возможность инвестировать в атрибуты и реализует настоящие здоровые инвестиции;
        <br/>Клеточная терапия + интеграция ресурсов + бизнес-субсидии = создание глобальной метавселенной стволовых клеток;
        <br/>ECC использует децентрализованную технологию блокчейна в качестве основы (BSC) в сочетании с NFT для кросс-международных визуализированных транзакций и модель залогового майнинга децентрализованного планового финансирования (DIFI) для создания экономики, которую можно использовать для субсидирования глобальной модели сотовой инженерии.</p>
      case 'de': //德国
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>
        Mainnet der Binance-Kette<br/>
        Netzwerkname: Binance Smart Chain<br/>
        RPC-URL: https://bsc-dataseed1.defibit.io/<br/>
        Smart Chain-ID: 56<br/>
        Symbol: BNB<br/>
        Browser-URL blockieren: https://bscscan.com<br/><br/>
        
        Vertragsadresse:<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F</p>
      case 'pt': //葡萄牙
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>O projeto de engenharia de células de cadeia ECC--BSC é investido pela Fundação Cingapura (ECC), usado principalmente no esquema de financiamento de projetos da empresa de biotecnologia de células Epocha e no subsídio do ecossistema global de células Future, e está comprometido em criar uma plataforma descentralizada para estabelecer informações O centro de gestão e a plataforma de descontos são utilizados para gerenciar um ecossistema que integra tratamento, drenagem, rastreamento e processamento de informações. A ecologia da Epochal e do Futuro utiliza a ECC como meio de circulação para realizar a popularização da terapia celular global e o apoio financeiro para a aplicação da engenharia celular global.
        <br/>Como ponto de partida da globalização das células-tronco, a ECC irá subverter muitos campos, como terapia celular, investimento em saúde e consumo transnacional;
        <br/>Cell NFT + mineração de promessas, capacita os usuários a investir em atributos e realiza um investimento real e saudável;
        <br/>Terapia celular + integração de recursos + subsídios empresariais = criação de um metaverso global de células-tronco;
        <br/>A ECC usa a tecnologia blockchain descentralizada como base (BSC), combinada com NFT para transações visualizadas internacionais, e o modelo de mineração de promessas de Decentralized Plan Finance (DIFI) para estabelecer uma economia que pode ser usada para subsidiar o modelo global de engenharia celular.</p>
      case 'it': //意大利
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>Il progetto di ingegneria cellulare a catena ECC--BSC è investito dalla Fondazione Singapore (ECC), utilizzata principalmente nello schema di finanziamento del progetto della società di biotecnologia cellulare Epocha e nel sussidio per l'ecosistema cellulare globale Future, e si impegna a creare una piattaforma decentralizzata per stabilire pazienti informazioni Il centro di gestione e la piattaforma di sconti vengono utilizzati per gestire un ecosistema che integra il trattamento, il drenaggio, il monitoraggio e l'elaborazione delle informazioni. L'ecologia di Epochal e Future utilizza l'ECC come mezzo di circolazione per realizzare la divulgazione della terapia cellulare globale e il sostegno finanziario per l'applicazione dell'ingegneria cellulare globale.
        <br/>Come punto di partenza della globalizzazione delle cellule staminali, l'ECC sovvertirà molti campi come la terapia cellulare, gli investimenti sanitari e il consumo transnazionale;
        <br/>Cell NFT + pledge mining, consente agli utenti di investire in attributi e realizza investimenti davvero salutari;
        <br/>Terapia cellulare + integrazione delle risorse + sussidi alle imprese = creazione di un metaverso globale delle cellule staminali;
        <br/>ECC utilizza la tecnologia blockchain decentralizzata come base (BSC), combinata con NFT per transazioni visualizzate internazionali e il modello di pegno mining di Decentralized Plan Finance (DIFI) per stabilire un'economia che può essere utilizzata per sovvenzionare il modello globale di ingegneria cellulare.</p>
      case 'bd': //孟加拉
        return <p style={{color:'black', fontSize:12, textTransform:'initial'}}>
        বিন্যান্স চেইন মেইননেট<br/>
        নেটওয়ার্কের নাম: Binance Smart Chain<br/>
        RPC URL: https://bsc-dataseed1.defibit.io/<br/>
        স্মার্ট চেইন আইডি: 56<br/>
        প্রতীক: বিএনবি<br/>
        ব্রাউজার URL ব্লক করুন: https://bscscan.com<br/><br/>
        
        চুক্তির ঠিকানা:<br/>
        BNB:
        0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c<br/>
        USDT:
        0x55d398326f99059fF775485246999027B3197955<br/>
        ECC:
        0xe864d06eeEC7Dd463C60be42c54886D238995919<br/>
        FNT I:
        0x1Be5913359DCc225C19f5045d3b9bfB6800Dd80B<br/>
        FNT II:
        0x0Ea09f5c7abC997F5ab2d41CF996BfAe444fd051<br/>
        FNT III:
        0xdEBfc78474E7320Be76170a5EC85481FC27FE14F</p>
      default:
      return
    }
  }

  return (
    <div className="layui-fluid">
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card" style={{ height: 50, marginBottom: 0 }}>
            <div
              style={{
                height: '100%',
                paddingLeft: 15,
                paddingRight: 15,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span className="font_bold_14" style={{ flexShrink: 0 }}>
                {t('exchangeContent.about_ecc')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card">
            <div className="layui-card-body">
              <div
                className="context-flex-center"
                style={{ height: 'auto', justifyContent: 'flex-start' }}
              >
              {getdetail(i18n.language)}
              </div>
            </div>
          </div>
        </div>
        <div className="layui-col-md12">
          <div className="layui-card">
            <div className="layui-card-body">
              <div
                className="context-flex-center"
                style={{ height: 'auto', justifyContent: 'flex-start' }}
              >
              {getdetail2(i18n.language)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card">
            <div className="layui-card-body">
              <div className="context-flex-center">
                {t('exchangeContent.about_ecc_issuing_1')}
                <br /> {t('exchangeContent.about_ecc_issuing_2')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card">
            <div className="layui-card-body">
              <div
                className="context-flex-center"
                style={{ height: 'auto', justifyContent: 'flex-start' }}
              >
                <span>
                  {t('exchangeContent.about_ecc_issuing_3')}
                  <br />
                  {t('exchangeContent.about_ecc_issuing_4')}
                  <br />
                  {t('exchangeContent.about_ecc_issuing_5')}
                  <br />
                  {t('exchangeContent.about_ecc_issuing_6')}
                  <br />
                  {t('exchangeContent.about_ecc_issuing_7')}
                  <br />
                  {t('exchangeContent.about_ecc_issuing_8')}
                  <br />
                  {t('exchangeContent.about_ecc_issuing_9')}
                  <br />
                  <br />
                  {t('exchangeContent.about_ecc_issuing_10')}
                  <br /> {t('exchangeContent.about_ecc_issuing_11')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card" style={{ height: 50, marginBottom: 0 }}>
            <div
              style={{
                height: '100%',
                paddingLeft: 15,
                paddingRight: 15,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span className="font_bold_14" style={{ flexShrink: 0 }}>
                {t('exchangeContent.about_ecc_pur')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card">
            <div className="layui-card-body">
              <div
                className="context-flex-center"
                style={{ height: 'auto', justifyContent: 'flex-start' }}
              >
                <span>{t('exchangeContent.about_ecc_pur1')}</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default About;
