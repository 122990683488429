/* global layui $ */
import './style.css'
import React, { useState, useEffect, useContext } from 'react';
import myWallet from '../dashboard/utils/mywallet';
import { ethers } from 'ethers';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';
import StakeModal from './StakeModal';


function EccStake() {
  const { t, i18n } = useTranslation('common');
  const { account, ecc_addr, rateRecord, eccStake, nft_addr_1, nft_addr_2, nft_addr_3, eccStakeNFT} = useContext(ExchangeContext);
  const [totalpoints, setTotalpoints] = useState("0");
  const [nftbalance_1, setNftbalance_1] = useState("0");
  const [nftbalance_2, setNftbalance_2] = useState("0");
  const [nftbalance_3, setNftbalance_3] = useState("0");
  const [choose_nft, setChoose_nft] = useState(null);
  const [locklist, setlocklist] = useState([]);
  const [locknftlist, setlocknftlist] = useState([]);
  const [modal_show, setModal_show] = useState("0");
  const [modalnft_show, setModalnft_show] = useState("0");


  const getdetail = (lng, fontsize) => {
    switch (lng) {
      case 'cn':
        return <p style={{color:'black', fontSize:fontsize}}>ECC挖矿允许您从加密货币中赚取利息。如果您没有技能或时间去做，我们的挖矿服务可以让您被动增加财富。<br/>
        您可以随时请求提取加密货币，前提是在您选择的挖矿期间内，如您ECC挖矿周期为30天，您将允许在30天内随时进行提取。NFT挖矿周期为固定的90天后提取。<br/>
        挖矿收益以ECC每天直接记入您的收益余额。收益可能因市场波动而异。所有这些收益都显示在挖矿列表页面上。在您的挖矿周期内或结束后，您可以随时将ECC收益提取到账户余额。</p>
      case 'en':
        return <p style={{color:'black', fontSize:fontsize}}>ECC mining allows you to earn interest from cryptocurrencies. If you don't have the skills or time to do it, our mining service allows you to passively increase your wealth.<br/>
        You can request to withdraw cryptocurrency at any time, provided that within the mining period you choose, if your ECC mining cycle is 30 days, you will allow withdrawals at any time within 30 days. NFT mining cycle is fixed after 90 days.<br/>
        Mining earnings are directly credited to your earnings balance in ECC every day. Earnings may vary due to market volatility. All these earnings are displayed on the mining list page. During or after your mining cycle, you can withdraw your ECC earnings to your account balance at any time.</p>
      case 'jp':
        return <p style={{color:'black', fontSize:fontsize}}>ECCマイニングを使用すると、暗号通貨から関心を集めることができます。 あなたがそれをするスキルや時間がない場合、私たちのマイニングサービスはあなたがあなたの富を受動的に増やすことを可能にします。<br/>
        選択したマイニング期間内に、ECCマイニングサイクルが30日である場合、30日以内であればいつでも引き出しを許可することを条件として、いつでも暗号通貨の引き出しをリクエストできます。 NFTマイニングサイクルは90日後に修正されます。<br/>
        鉱業の収益は、ECCの収益残高に毎日直接入金されます。 市場のボラティリティにより、収益は変動する可能性があります。 これらの収益はすべて、マイニングリストページに表示されます。 マイニングサイクル中またはマイニングサイクル後は、いつでもECCの収益をアカウントの残高に引き出すことができます。</p>
      case 'sa': //阿拉伯
        return <p style={{color:'black', fontSize:fontsize, textAlign:'right'}}>يتيح لك تعدين ECC كسب الفائدة من العملات المشفرة. إذا لم تكن لديك المهارات أو الوقت للقيام بذلك ، فإن خدمة التعدين لدينا تتيح لك زيادة ثروتك بشكل سلبي. <br/>
        يمكنك طلب سحب العملة المشفرة في أي وقت ، بشرط أنه خلال فترة التعدين التي تختارها ، إذا كانت دورة تعدين ECC الخاصة بك 30 يومًا ، فستسمح بالسحب في أي وقت خلال 30 يومًا. يتم إصلاح دورة تعدين NFT بعد 90 يومًا. <br/>
        يتم إضافة أرباح التعدين مباشرة إلى رصيد أرباحك في ECC كل يوم. قد تختلف الأرباح بسبب تقلبات السوق. يتم عرض كل هذه الأرباح على صفحة قائمة التعدين. أثناء دورة التعدين أو بعدها ، يمكنك سحب أرباحك من ECC إلى رصيد حسابك في أي وقت.</p>
      case 'fr': //法国
        return <p style={{color:'black', fontSize:fontsize}}>L'exploitation minière ECC vous permet de gagner des intérêts sur les crypto-monnaies. Si vous n'avez pas les compétences ou le temps pour le faire, notre service de minage vous permet d'augmenter passivement votre patrimoine.<br/>
        Vous pouvez demander le retrait de la crypto-monnaie à tout moment, à condition que pendant la période de minage que vous choisissez, si votre cycle de minage ECC est de 30 jours, vous autorisez les retraits à tout moment dans les 30 jours. Le cycle de minage NFT est fixé après 90 jours.<br/>
        Les revenus miniers sont directement crédités sur votre solde de revenus dans ECC chaque jour. Les bénéfices peuvent varier en raison de la volatilité du marché. Tous ces gains sont affichés sur la page de liste de minage. Pendant ou après votre cycle de minage, vous pouvez retirer vos gains ECC sur le solde de votre compte à tout moment.</p>
      case 'de': //德国
        return <p style={{color:'black', fontSize:fontsize}}>Mit ECC-Mining können Sie Zinsen aus Kryptowährungen verdienen. Wenn Sie nicht die Fähigkeiten oder die Zeit dazu haben, können Sie mit unserem Mining-Service Ihr Vermögen passiv vermehren.<br/>
        Sie können jederzeit die Auszahlung von Kryptowährung beantragen, vorausgesetzt, dass Sie innerhalb des von Ihnen gewählten Mining-Zeitraums, wenn Ihr ECC-Mining-Zyklus 30 Tage beträgt, Auszahlungen jederzeit innerhalb von 30 Tagen zulassen. Der NFT-Mining-Zyklus ist nach 90 Tagen festgelegt.<br/>
        Mining-Einnahmen werden jeden Tag direkt Ihrem Verdienstguthaben in ECC gutgeschrieben. Die Erträge können aufgrund der Marktvolatilität variieren. Alle diese Einnahmen werden auf der Mining-Listenseite angezeigt. Während oder nach Ihrem Mining-Zyklus können Sie Ihre ECC-Einnahmen jederzeit auf Ihr Kontoguthaben abheben.</p>
      case 'bd': //孟加拉
        return <p style={{color:'black', fontSize:fontsize}}>ECC মাইনিং আপনাকে ক্রিপ্টোকারেন্সি থেকে সুদ উপার্জন করতে দেয়। আপনার যদি এটি করার দক্ষতা বা সময় না থাকে, তাহলে আমাদের মাইনিং পরিষেবা আপনাকে নিষ্ক্রিয়ভাবে আপনার সম্পদ বাড়াতে দেয়৷<br/>
        আপনি যেকোন সময় ক্রিপ্টোকারেন্সি প্রত্যাহার করার জন্য অনুরোধ করতে পারেন, শর্ত থাকে যে আপনার বেছে নেওয়া খনির সময়কালের মধ্যে, যদি আপনার ECC মাইনিং চক্র 30 দিনের হয়, আপনি 30 দিনের মধ্যে যে কোনো সময় প্রত্যাহারের অনুমতি দেবেন। NFT মাইনিং চক্র 90 দিন পরে ঠিক করা হয়৷<br/>৷
        মাইনিং আয় প্রতিদিন আপনার উপার্জনের ব্যালেন্সে সরাসরি জমা হয়। বাজারের অস্থিরতার কারণে আয় পরিবর্তিত হতে পারে। এই সমস্ত উপার্জন খনির তালিকা পৃষ্ঠায় প্রদর্শিত হয়। আপনার মাইনিং চক্র চলাকালীন বা পরে, আপনি যেকোন সময় আপনার অ্যাকাউন্ট ব্যালেন্সে আপনার ECC উপার্জন তুলে নিতে পারেন।</p>
      default: //孟加拉
        return
    }
  }

  const getSubStr = str => {
    var subStr1 = str.substr(0, 6);
    var subStr2 = str.substr(str.length - 4, 4);
    var subStr = subStr1 + '...' + subStr2;
    return subStr;
  };

  const getdate = (timestamp) => {
    //美国东部时间
    if (timestamp === 0) return 0;
    let date = new Date(timestamp*1000 - (12*3600*1000));
    let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    return date2;


    //北京时间
    // if (timestamp === 0) return 0;
    // let date = new Date(timestamp*1000);
    // let date2 = date.toLocaleDateString().replace(/\//g, "-") + " " + date.toTimeString().substr(0, 8);
    // return date2;
  }

  const call_withdraw = async (method_name, params, element) => {
    if (account == null) {
      layui.layer.msg(t('exchangeContent.please_connect'));
      return;
    }
    let EccStake = myWallet.getContract(eccStake);
    let EccStakeNFT = myWallet.getContract(eccStakeNFT);
    if (EccStake != null && EccStakeNFT != null) {
      switch (method_name) {
        case "withdrawECC":
        {
          let gas = await EccStake.estimateGas.withdrawECC(params).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await EccStake.withdrawECC(params, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.show();
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        case "withdrawNFT":
        {
          let gas = await EccStakeNFT.estimateGas.withdrawNFT(params).catch((err) => {
            console.error(err)
            layui.layer.msg(err.error.message);
          });
          if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await EccStakeNFT.withdrawNFT(params, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            let success = method_name + " " + t('exchangeContent.success')
            let fail = method_name + " " + t('exchangeContent.fail')
            if (typeof tx !== 'undefined') {
              addTxcheck(tx.hash, (receipt) => {
                element.hide();
                if (receipt === 1) {
                  layui.layer.msg(success);
                }
                else {
                  layui.layer.msg(fail);
                }
                update_balance(account);
              });
              element.show();
            }
            else {
              layui.layer.msg(fail);
              return
            }
          }
        }
        break;
        default:
        break;
      }
    }
  };

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt.status);
        clearInterval(ins);
      }
    }, 1000);
  };

  const getNFTName = (addr) => {
    if (addr.toLowerCase() === nft_addr_1.toLowerCase()) {
      return "FNT Ⅰ"
    }
    else if (addr.toLowerCase() === nft_addr_2.toLowerCase()) {
      return "FNT Ⅱ"
    }
    else {
      return "FNT Ⅲ"
    }
  }

  const update_balance = async _account => {
    if (_account == null) return;
    let ecctoken = myWallet.getContract(ecc_addr);
    let nft1 = myWallet.getContract(nft_addr_1);
    let nft2 = myWallet.getContract(nft_addr_2);
    let nft3 = myWallet.getContract(nft_addr_3);
    let RateRecord = myWallet.getContract(rateRecord);
    let EccStake = myWallet.getContract(eccStake);
    let EccStakeNFT = myWallet.getContract(eccStakeNFT);
    if (EccStake != null && EccStakeNFT != null && ecctoken != null && nft1 != null && nft2 != null && nft3 != null && RateRecord != null) {
      let balance = await ecctoken.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance !== "undefined") {
        setTotalpoints(ethers.utils.formatUnits(balance.toString(), 18).toString())
      }

      let balance1 = await nft1.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance1 !== "undefined") {
        setNftbalance_1(balance1.toNumber())
      }

      let balance2 = await nft2.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance2 !== "undefined") {
        setNftbalance_2(balance2.toNumber())
      }

      let balance3 = await nft3.balanceOf(_account).catch((err) => {console.error(err)})
      if (typeof balance3 !== "undefined") {
        setNftbalance_3(balance3.toNumber())
      }

      let currentDay = await RateRecord.currentTime().catch((err) => {console.error(err)})
      let stakeCount = await EccStake.getStakeCount(_account).catch((err) => {console.error(err)})
      if (typeof currentDay !== "undefined" && typeof stakeCount !== "undefined") {
        stakeCount = stakeCount.toNumber();
        let orders = [];
        let total = ethers.utils.parseEther("0");
        for (let i = 0; i < stakeCount; i++) {
          let result = await EccStake.getStakeInfo(_account, i).catch(err => {console.error(err)});
          if (typeof result !== "undefined") {
              //今天收益
                let stakeInfo = result[0];
                let earnings_today = result[1];
                if (stakeInfo.withdrawTime.gt(ethers.utils.parseEther("0"))) { ///已经提币
                    let obj = {
                        id:i,
                        mode: stakeInfo.mode,
                        quantity: ethers.utils.formatUnits(stakeInfo.quantity.toString(), 18).toString(),
                        beginTime:getdate(stakeInfo.beginTime.toNumber()),
                        endTime:getdate(stakeInfo.endTime.toNumber()),
                        earnings_today: parseFloat(ethers.utils.parseEther("0").toString()).toFixed(4),
                        earnings_total: parseFloat(ethers.utils.formatUnits(stakeInfo.income.toString(), 18).toString()).toFixed(4),
                        withdrawTime: getdate(stakeInfo.withdrawTime.toNumber()),
                        income: parseFloat(ethers.utils.formatUnits(stakeInfo.income.toString(), 18).toString()).toFixed(4),
                        withdraw: true
                    }
                    orders.push(obj);
                }
                else {
                    let obj = {
                        id:i,
                        mode: stakeInfo.mode,
                        quantity: ethers.utils.formatUnits(stakeInfo.quantity.toString(), 18).toString(),
                        beginTime:getdate(stakeInfo.beginTime.toNumber()),
                        endTime:getdate(stakeInfo.endTime.toNumber()),
                        earnings_today: parseFloat(ethers.utils.formatUnits(earnings_today.toString(), 18).toString()).toFixed(4),
                        earnings_total: parseFloat(ethers.utils.formatUnits(stakeInfo.income.toString(), 18).toString()).toFixed(4),
                        withdraw: false
                    }
                    total = total.add(stakeInfo.income);
                    orders.push(obj);
                }
            }
        }
        setlocklist(orders);
        //setWithdrawpoints(ethers.utils.formatUnits(total.toString(), 18).toString());
      }

      let stakeNFTCount = await EccStakeNFT.getStakeCount(_account).catch((err) => {console.error(err)})
      if (typeof currentDay !== "undefined" && typeof stakeNFTCount !== "undefined") {
        stakeNFTCount = stakeNFTCount.toNumber();
        let orders = [];
        let total = ethers.utils.parseEther("0");
        for (let i = 0; i < stakeNFTCount; i++) {
          let result = await EccStakeNFT.getStakeInfo(_account, i).catch(err => {console.error(err)});
          if (typeof result !== "undefined") {
            //今天收益
            let stakeInfo = result[0];
            let earnings_today = result[1];
            if (stakeInfo.withdrawTime.gt(ethers.utils.parseEther("0"))) { ///已经提币
                let obj = {
                    id:i,
                    nftName: getNFTName(stakeInfo.nft),
                    tokenId: stakeInfo.tokenId.toNumber(),
                    beginTime:getdate(stakeInfo.beginTime.toNumber()),
                    endTime:getdate(stakeInfo.endTime.toNumber()),
                    earnings_today: parseFloat(ethers.utils.parseEther("0").toString()).toFixed(4),
                    earnings_total: parseFloat(ethers.utils.formatUnits(stakeInfo.income.toString(), 18).toString()).toFixed(4),
                    withdrawTime: getdate(stakeInfo.withdrawTime.toNumber()),
                    income: parseFloat(ethers.utils.formatUnits(stakeInfo.income.toString(), 18).toString()).toFixed(4),
                    withdraw: true
                }
                orders.push(obj);
            }
            else {
                let obj = {
                    id:i,
                    nftName: getNFTName(stakeInfo.nft),
                    tokenId: stakeInfo.tokenId.toNumber(),
                    beginTime:getdate(stakeInfo.beginTime.toNumber()),
                    endTime:getdate(stakeInfo.endTime.toNumber()),
                    earnings_today: parseFloat(ethers.utils.formatUnits(earnings_today.toString(), 18).toString()).toFixed(4),
                    earnings_total: parseFloat(ethers.utils.formatUnits(stakeInfo.income.toString(), 18).toString()).toFixed(4),
                    withdraw: false
                }
                total = total.add(stakeInfo.income);
                orders.push(obj);
            }
          }
        }
        setlocknftlist(orders);
        //setWithdrawpoints(ethers.utils.formatUnits(total.toString(), 18).toString());
      }
    }
  };

  

  useEffect(() => {
    update_balance(account);
  }, [account]);

  return (
    <>
      <div className="layui-fluid">
          <div className="layui-row layui-col-space15">
            <div className="layui-col-md12">
              <div className="layui-card" style={{height:50,marginBottom:0}}>
                <div style={{height:'100%',paddingLeft:15,paddingRight:15,display:'flex',alignItems:'center'}}>
                  <span className="font_bold_14" style={{flexShrink:0}}>{t('exchangeContent.walletaddress')}</span>
                  <span className="value layui-hide-xs" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':account}</span>
                  <span className="value layui-hide-sm layui-show-xs-inline" style={{'textAlign':'left','wordBreak':'break-all',marginLeft:10}}>{account==null?'-':getSubStr(account)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="layui-row layui-col-space15">
            <div className="layui-col-md3">
              <div className="layui-card">
                <div className="layui-card-header context-flex-space-between">{t('stake.stakeablepoints')}
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                      setModal_show("1")
                    }}>
                    <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                    {t('stake.stack')}
                  </button>
                </div>
                  <div className="layui-card-body">
                    <div className="context-flex-center">
                      <span className="value" style={{'fontSize':18}}>{parseFloat(totalpoints.toString()).toFixed(4)}</span>
                    </div>
                  </div>
              </div>
            </div>
            <div className="layui-col-md3">
              <div className="layui-card">
                <div className="layui-card-header context-flex-space-between">{"FNT Ⅰ " + t('exchangeContent.balance')}
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                      setChoose_nft(nft_addr_1)
                      setModalnft_show("1")
                    }}>
                    <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                    {t('stake.stack')}
                  </button>
                </div>
                  <div className="layui-card-body">
                    <div className="context-flex-center">
                      <span className="value" style={{'fontSize':18}}>{nftbalance_1}</span>
                    </div>
                  </div>
              </div>
            </div>
            <div className="layui-col-md3">
              <div className="layui-card">
                <div className="layui-card-header context-flex-space-between">{"FNT Ⅱ " + t('exchangeContent.balance')}
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                      setChoose_nft(nft_addr_2)
                      setModalnft_show("1")
                    }}>
                    <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                    {t('stake.stack')}
                  </button>
                </div>
                  <div className="layui-card-body">
                    <div className="context-flex-center">
                      <span className="value" style={{'fontSize':18}}>{nftbalance_2}</span>
                    </div>
                  </div>
              </div>
            </div>
            <div className="layui-col-md3">
              <div className="layui-card">
                <div className="layui-card-header context-flex-space-between">{"FNT Ⅲ " + t('exchangeContent.balance')}
                <button type="button" className="btn btn-primary" style={{margin:'5px 0px'}} onClick={()=>{
                      setChoose_nft(nft_addr_3)
                      setModalnft_show("1")
                    }}>
                    <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                    {t('stake.stack')}
                  </button>
                </div>
                  <div className="layui-card-body">
                    <div className="context-flex-center">
                      <span className="value" style={{'fontSize':18}}>{nftbalance_3}</span>
                    </div>
                  </div>
              </div>
            </div>
            <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header">{t('stake.rule')}</div>
                <div className="layui-card-body">
                  <div
                    className="context-flex-center"
                    style={{ height: 'auto', justifyContent: 'flex-start' }}
                  >
                  {getdetail(i18n.language, 15)}
                  </div>
                </div>
              </div>
            </div>
            <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header">{t('stake.locklist')}</div>
                <div className="layui-card-body">
                  <div className="layui-row layui-col-space15">
                  {
                    locklist.map((item) => {
                      return <div className="layui-col-md4" id={"stack_ecc" + item.id} key={"stack_ecc" + item.id} index={item.id}>
                              <div className="layui-card">
                                <div className="layui-card-header">ID：{item.id}</div>
                                  <div className="layui-card-body" style={{padding:'5px 5px'}}>
                                    <div className="context-flex-column" style={{height:'auto'}}>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.period")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.mode === 1 ? t('stake.period_1') : t('stake.period_2')}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.quantity")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.quantity}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.start_stake")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.beginTime}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.end_stake")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.endTime}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.earnings_today")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.earnings_today}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.earnings_total")} :</span>
                                        <span className="value" style={{'fontSize':16, color:'red'}}>{item.earnings_total}</span>
                                      </div>
                                      {
                                          item.withdraw ? <div className='context-flex-space-between'>
                                                              <span className="value" style={{'fontSize':16}}>{t("stake.withdrawTime")} :</span>
                                                              <span className="value" style={{'fontSize':16, color:'red'}}>{item.withdrawTime}</span>
                                                          </div> : ''
                                      }
                                      {
                                          item.withdraw ? <div className='context-flex-space-between'>
                                                              <span className="value" style={{'fontSize':16}}>{t("stake.income")} :</span>
                                                              <span className="value" style={{'fontSize':16, color:'red'}}>{item.income}</span>
                                                          </div> : ''
                                      }
                                      {
                                          !item.withdraw ? <button type="button" className="btn btn-primary" style={{marginTop:14}} onClick={()=>{
                                                              let element = $("#" + item.id + " i.layui-icon-loading");
                                                              call_withdraw("withdrawECC", item.id, element)
                                                          }}>
                                                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                                                          {t('exchangeContent.withdraw')}
                                                          </button> : ''
                                      }
                                    </div>
                                  </div>
                              </div>
                            </div>
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
            <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header">{t('stake.locknftlist')}</div>
                <div className="layui-card-body">
                  <div className="layui-row layui-col-space15">
                  {
                    locknftlist.map((item) => {
                      return <div className="layui-col-md4" id={"stack_nft" + item.id} key={"stack_nft" + item.id} index={item.id}>
                              <div className="layui-card">
                                <div className="layui-card-header">ID：{item.id}</div>
                                  <div className="layui-card-body" style={{padding:'5px 5px'}}>
                                    <div className="context-flex-column" style={{height:'auto'}}>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.period")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{t('stake.period_nft')}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>NFT :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.nftName}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.start_stake")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.beginTime}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.end_stake")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.endTime}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.earnings_today")} :</span>
                                        <span className="value" style={{'fontSize':16}}>{item.earnings_today}</span>
                                      </div>
                                      <div className='context-flex-space-between'>
                                        <span className="value" style={{'fontSize':16}}>{t("stake.earnings_total")} :</span>
                                        <span className="value" style={{'fontSize':16, color:'red'}}>{item.earnings_total}</span>
                                      </div>
                                      {
                                          item.withdraw ? <div className='context-flex-space-between'>
                                                              <span className="value" style={{'fontSize':16}}>{t("stake.withdrawTime")} :</span>
                                                              <span className="value" style={{'fontSize':16, color:'red'}}>{item.withdrawTime}</span>
                                                          </div> : ''
                                      }
                                      {
                                          item.withdraw ? <div className='context-flex-space-between'>
                                                              <span className="value" style={{'fontSize':16}}>{t("stake.income")} :</span>
                                                              <span className="value" style={{'fontSize':16, color:'red'}}>{item.income}</span>
                                                          </div> : ''
                                      }
                                      {
                                          !item.withdraw ? <button type="button" className="btn btn-primary" style={{marginTop:14}} onClick={()=>{
                                                              let element = $("#" + item.id + " i.layui-icon-loading");
                                                              call_withdraw("withdrawNFT", item.id, element)
                                                          }}>
                                                          <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5,display:'none'}}></i>
                                                          {t('exchangeContent.withdraw')}
                                                          </button> : ''
                                      }
                                    </div>
                                  </div>
                              </div>
                            </div>
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <StakeModal 
        type={0}
        modal_show={modal_show}
        setModal_show={setModal_show}
        ecc_addr={ecc_addr}
        myWallet={myWallet}
        eccStake={eccStake} />
      <StakeModal 
        type={1}
        modal_show={modalnft_show}
        setModal_show={setModalnft_show}
        myWallet={myWallet}
        eccStake={eccStake}
        nft={choose_nft}
        eccStakeNFT={eccStakeNFT} />
    </>
  );
}

export default EccStake;
