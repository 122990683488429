import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_cn from "./locales/cn/translation.json";
import translation_en from "./locales/en/translation.json";
import translation_jp from "./locales/jp/translation.json";
import translation_sa from "./locales/sa/translation.json";
import translation_es from "./locales/es/translation.json";
import translation_fr from "./locales/fr/translation.json";
import translation_ru from "./locales/ru/translation.json";
import translation_de from "./locales/de/translation.json";
import translation_pt from "./locales/pt/translation.json";
import translation_it from "./locales/it/translation.json";
import translation_bd from "./locales/bd/translation.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        common: translation_en               // 'common' is our custom namespace
    },
    cn: {
        common: translation_cn
    },
    jp: {
        common: translation_jp
    },
    sa: {
        common: translation_sa
    },
    es: {
        common: translation_es
    },
    fr: {
        common: translation_fr
    },
    ru: {
        common: translation_ru
    },
    de: {
        common: translation_de
    },
    pt: {
        common: translation_pt
    },
    it: {
        common: translation_it
    },
    bd: {
        common: translation_bd
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

     // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;