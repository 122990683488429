import './App.css'
import React, { useState, createContext } from 'react';
import ExchangeMain from './components/dashboard/parts/ExchangeMain';
import myWallet from './components/dashboard/utils/mywallet';
import { useLocation, Route, Switch, Link } from 'react-router-dom';
import EccNFTSingle from './components/dashboard/EccNFTSingle';
import '.'


const ExchangeContext = createContext();


const App = () => {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);


  return (
    <ExchangeContext.Provider
      value={{
        account,
        setAccount,
        balance,
        setBalance,
        myWallet,
        admin_addr: [
          process.env.REACT_APP_ADMIN,
          process.env.REACT_APP_ADMIN2,
          process.env.REACT_APP_ADMIN3,
          process.env.REACT_APP_ADMIN4
        ],
        ecc_addr: process.env.REACT_APP_ECC,
        swap_addr: process.env.REACT_APP_SWAP,
        exchange_c: process.env.REACT_APP_SWAPC,
        usdt_addr: process.env.REACT_APP_USDT,
        nft_addr_1: process.env.REACT_APP_NFT_1,
        nft_addr_2: process.env.REACT_APP_NFT_2,
        nft_addr_3: process.env.REACT_APP_NFT_3,
        trade_addr: process.env.REACT_APP_TRADE,
        airdrop_addr_1: process.env.REACT_APP_AIRDROP_1,
        airdrop_addr_2: process.env.REACT_APP_AIRDROP_2,
        airdrop_addr_3: process.env.REACT_APP_AIRDROP_3,
        nft_back: process.env.REACT_APP_NFTBACK,
        sale_addr: process.env.REACT_APP_SALE,
        proxyAdmin_addr_1: process.env.REACT_APP_ProxyAdmin_1,
        proxyAdmin_addr_2: process.env.REACT_APP_ProxyAdmin_2,
        rateRecord: process.env.REACT_APP_ECCRateRecord,
        eccStake: process.env.REACT_APP_ECCStake,
        eccStakeNFT: process.env.REACT_APP_ECCStakeNFT
      }}
    >
      <Switch>
          <Route exact path={'/transfer'}>
            <EccNFTSingle />
          </Route>
          <Route path={'/'}>
            <ExchangeMain />
          </Route>
      </Switch>
    </ExchangeContext.Provider>
  );
};
export { ExchangeContext };
export default App;
