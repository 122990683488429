/* global layui $ */
import React, { useState, useEffect, useContext } from 'react';
import myWallet from './utils/mywallet';
import { ethers } from 'ethers';
import { ExchangeContext } from '../../App';
import { useTranslation } from 'react-i18next';

function ExchangeSwap_b() {
  const { t } = useTranslation('common');
  const { account, ecc_addr, swap_addr} = useContext(ExchangeContext);
  const [balance_ecc, setBalance_ecc] = useState('-');
  const [lockedpoints, setLockedpoints] = useState("-");
  const [unlockedpoints, setUnlockedpoints] = useState("-");
  const [exchangeRate, setExchangeRate] = useState('-');
  const [loading_swap, setLoading_swap] = useState(false);
  const [admin, setAdmin] = useState("-");
  const [loading_withdrawBaseToken, setLoading_withdrawBaseToken] = useState(false);
  const [loading_withdrawETH, setLoading_withdrawETH] = useState(false);
  const [loading_updateExchangeRate, setLoading_updateExchangeRate] = useState(false);


  const getSubStr = str => {
    var subStr1 = str.substr(0, 6);
    var subStr2 = str.substr(str.length - 4, 4);
    var subStr = subStr1 + '...' + subStr2;
    return subStr;
  };

  const _do_method = async (contract, method_name, params) => {
    let estimateGas_fun = contract.estimateGas[method_name];
    let gas = await estimateGas_fun.apply(this, params).catch(err => {
      console.error(err);
    });
    if (typeof gas !== 'undefined') {
      let gaslimit = Math.round(gas.toNumber() * 2);
      let fun = contract[method_name];
      params.push({ gasLimit: gaslimit });
      let tx = await fun.apply(this, params).catch(err => {
        console.error(err);
      });
      return tx;
    }
  };

  const check_inputs = (inputs, _loading) => {
    if (account == null || _loading) {return}
    let params = [];
    for (let i = 0; i < inputs.length; i++) {
      let input = $(inputs.get(i));
      let placeholder_txt = input.attr("placeholder");
      if (input.val() === '') {
        layui.layer.alert(t(placeholder_txt));
        return
      }
      let param_type = input.attr("param_type");
      switch (param_type) {
        case "string":
        case "address":
          params.push(input.val())
          break;
        case "uint":
          params.push(ethers.BigNumber.from(input.val()))
          break;
        case "amount":
          {
            let decimals = input.attr("decimals");
            params.push(ethers.utils.parseUnits(input.val(), parseInt(decimals)))
          }
          break;
        default:
          break;
      }
    }
    return params
  }

  const call_method = async (method_name, params, _setloading) => {
    if (account == null) {return}
    let signer =  myWallet.getContract(swap_addr);
    if (signer != null) {
      //estimateGas
      let estimateGas_fun = signer.estimateGas[method_name];
      let gas = await estimateGas_fun.apply(this,params).catch((err) => {console.error(err)});
      if (typeof gas !== "undefined") {
        let gaslimit = Math.round(gas.toNumber() * 2);
        let fun = signer[method_name]
        params.push({"gasLimit":gaslimit});
        let tx = await fun.apply(this,params).catch((err) => {console.error(err)});
        let success = method_name + " " + t('exchangeContent.success')
        let fail = method_name + " " + t('exchangeContent.fail')
        if (typeof tx !== 'undefined') {
          addTxcheck(tx.hash, (receipt) => {
            _setloading(false);
            if (receipt === 1) {
              layui.layer.msg(success);
            }
            else {
              layui.layer.msg(fail);
            }
            update_balance(account);
          });
          _setloading(true);
        }
        else {
          layui.layer.msg(fail);
          return
        }
      }
    }
  }

  const addTxcheck = (hash, callback) => {
    let running = true;
    let ins = setInterval(async () => {
      let receipt = await myWallet.getTransactionReceipt(hash);
      if (receipt != null && running) {
        running = false;
        callback(receipt.status);
        clearInterval(ins);
      }
    }, 1000);
  };

  const update_balance = async _account => {
    if (_account == null) return;
    let ecc = myWallet.getContract(ecc_addr);
    let swap = myWallet.getContract(swap_addr);
    if (ecc != null && swap != null) {
      let bb = await ecc.balanceOf(_account).catch(err => {
        console.error(err);
      });
      if (typeof bb !== 'undefined') {
        setBalance_ecc(parseFloat(ethers.utils.formatUnits(bb.toString(), 18).toString()).toFixed(4));
      }

      let info = await swap.lockMap(_account).catch(err => {console.error(err)});
      if (typeof info !== 'undefined') {
        setLockedpoints(parseFloat(ethers.utils.formatUnits(info.balance.toString(), 18).toString()).toFixed(4));
      }

      let release = await swap.show_release().catch(err => {console.error(err)});
      if (typeof release !== 'undefined') {
        setUnlockedpoints(parseFloat(ethers.utils.formatUnits(release.toString(), 18).toString()).toFixed(4));
      }

      let _owner = await swap.owner().catch(err => {console.error(err)});
      if (typeof _owner !== 'undefined') {
        setAdmin(_owner);
      }

      let _exchangeRate = await swap.exchangeRate().catch(err => {
        console.error(err);
      });
      if (typeof _exchangeRate !== 'undefined') {
        setExchangeRate(_exchangeRate.toString());
      }
    }
  };

  useEffect(() => {
    update_balance(account);
    layui.use('form', function() {
      var form = layui.form;
      form.on('submit(formswap)', async data => {
        if (account !== null && !loading_swap) {
          let msg = $('#memo_input').val();
          let tx = await myWallet
            .sendTransaction(account, swap_addr, data.field.amount, msg)
            .catch(err => {
              console.error(err);
            });
          let success = 'Subscribe' + t('exchangeContent.success');
          let fail = 'Subscribe' + t('exchangeContent.fail');
          if (typeof tx !== 'undefined') {
            addTxcheck(tx, function(status) {
              if (status === 1) {
                layui.layer.msg(success);
                update_balance(account);
              } else {
                layui.layer.msg(fail);
              }
              setLoading_swap(false);
            });
            setLoading_swap(true);
          } else {
            layui.layer.msg(fail);
            return;
          }
        }

        return false;
      });
    });
  }, [account]);

  return (
    <div className="layui-fluid">
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card" style={{ height: 50, marginBottom: 0 }}>
            <div
              style={{
                height: '100%',
                paddingLeft: 15,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span className="font_bold_14" style={{ flexShrink: 0 }}>
                {t('exchangeContent.walletaddress')}
              </span>
              <span
                className="value layui-hide-xs"
                style={{
                  textAlign: 'left',
                  wordBreak: 'break-all',
                  marginLeft: 10
                }}
              >
                {account == null ? '-' : account}
              </span>
              <span
                className="value layui-hide-sm layui-show-xs-inline"
                style={{
                  textAlign: 'left',
                  wordBreak: 'break-all',
                  marginLeft: 10
                }}
              >
                {account == null ? '-' : getSubStr(account)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="layui-row layui-col-space15">
          <div className="layui-col-md4">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.totalpoints')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span id="balance" className="value" style={{'fontSize':18}}>{balance_ecc}</span>
                  </div>
                </div>
            </div>
          </div>
          <div className="layui-col-md4">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.lockpoints')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span id="balance" className="value" style={{'fontSize':18}}>{lockedpoints}</span>
                  </div>
                </div>
            </div>
          </div>
          <div className="layui-col-md4">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.unlockpoints')}
                <button type="button" className="btn btn-primary" style={{float:'right',marginTop:5}} onClick={()=>{call_method("withdraw_order", [], setLoading_withdraw_order)}}>
                {
                  loading_withdraw_order === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                }
                {t('exchangeContent.withdraw_order')}
                </button>
              </div>
              <div className="layui-card-body">
                <div className="context-flex-center">
                  <span id="balance" className="value" style={{'fontSize':18}}>{unlockedpoints}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header">{t('exchangeContent.orderlist')}</div>
                <div className="layui-card-body">
                  <table className="layui-table" lay-size="sm" lay-skin="line" id="orderlist"></table>
                </div>
              </div>
          </div>
      </div> */}
      {/* <div className="layui-row layui-col-space15">
          <div className="layui-col-md6">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.totalpoints')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span className="value" style={{'fontSize':26}}>{balance_ecc}</span>
                  </div>
                </div>
            </div>
          </div>
          <div className="layui-col-md6">
            <div className="layui-card">
              <div className="layui-card-header">{t('exchangeContent.usdtbalance')}</div>
                <div className="layui-card-body">
                  <div className="context-flex-center">
                    <span className="value" style={{'fontSize':26}}>{balance_eth}</span>
                  </div>
                </div>
            </div>
          </div>
        </div> */}
      <div className="layui-row layui-col-space15">
        <div className="layui-col-md12">
          <div className="layui-card">
            <div
              className="layui-card-header"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {t('exchangeContent.swapInfo_b')}
            </div>
            <div className="layui-card-body">
              <form
                className="layui-form"
                action=""
                lay-filter="component-form-element"
              >
                <div className="layui-row layui-col-space10 layui-form-item">
                  {/*  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.swap_ratio')}：
                    </label>
                    <label
                      className="layui-form-label"
                      style={{
                        textAlign: 'right',
                        width: 'auto',
                        paddingLeft: 8
                      }}
                    >
                      {'1 BNB = '+exchangeRate+' ECC'}
                    </label>
                  </div>
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.swap_points')}：
                    </label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150 }}
                    >
                      <input
                        type="number"
                        name="amount"
                        disabled="disabled"
                        lay-verify="required"
                        placeholder={t('exchangeContent.input_usdt')}
                        autoComplete="off"
                        className="layui-input"
                      />
                    </div>
                  </div>
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.swap_address')}：
                    </label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150 }}
                    >
                      <input
                        defaultValue={account}
                        type="text"
                        name="address"
                        lay-verify="required"
                        placeholder={t('exchangeContent.input_set_address')}
                        autoComplete="off"
                        className="layui-input"
                      />
                    </div>
                  </div>
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    >
                      {t('exchangeContent.memo')}：
                    </label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150 }}
                    >
                      <input
                        id="memo_input"
                        type="text"
                        name="memo"
                        disabled="disabled"
                        placeholder={t('exchangeContent.input_memo')}
                        autoComplete="off"
                        className="layui-input"
                      />
                    </div>
                  </div> */}
                  <div className="layui-col-lg12">
                    <label
                      className="layui-form-label"
                      style={{
                        width: 142,
                        textAlign: 'right',
                        paddingLeft: 0,
                        paddingRight: 0
                      }}
                    ></label>
                    <div
                      className="layui-input-block"
                      style={{ marginLeft: 150, color: 'red' }}
                    >
                      {t('exchangeContent.waite_b')}
                    </div>
                  </div>
                </div>
                {/* <div className="layui-form-item">
                  <div
                    className="layui-input-block"
                    style={{ marginLeft: 150 }}
                  >
                    <button
                      className="btn btn-primary"
                      type="button"
                      lay-submit=""
                      lay-filter="formswap"
                      style={{ marginRight: 10 }}
                    >
                      {loading_swap === true ? (
                        <i
                          className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop"
                          style={{ marginRight: 5 }}
                        ></i>
                      ) : (
                        ''
                      )}
                      {t('exchangeContent.submit')}
                    </button>
                    <button type="reset" className="btn btn-outline-primary">
                      {t('exchangeContent.reset')}
                    </button>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={
            account !== null ? (admin.toLowerCase() === account.toLowerCase() ? 'layui-row layui-col-space15':'hide'):'hide'
          }>
          <div className="layui-col-md12">
              <div className="layui-card">
                <div className="layui-card-header" style={{'display':'flex','alignItems':'center'}}>{t('exchangeContent.method')}
                </div>
                <div className="layui-card-body">
                  <div className="layui-row layui-col-space10">
                    <div className="layui-col-md3">
                      <div className="context-flex-column border_context" style={{'height':'auto'}}>
                        <button type="button" className="btn btn-primary" onClick={()=>{
                            let params = check_inputs($("input.withdrawBaseToken_params"),loading_withdrawBaseToken);
                            if (params instanceof Array) {call_method("withdrawBaseToken", params, setLoading_withdrawBaseToken)}
                          }}>
                          {
                            loading_withdrawBaseToken === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                          }
                          {t('exchangeContent.withdrawBaseToken')}
                        </button>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.amount')} :</span>
                          <input param_type="amount" decimals="18" type="number" autoComplete="off" placeholder={t('exchangeContent.input_set_amount')} className="layui-input withdrawBaseToken_params"></input>
                        </div>
                      </div>
                    </div>
                    <div className="layui-col-md3">
                      <div className="context-flex-column border_context" style={{'height':'auto'}}>
                        <button type="button" className="btn btn-primary" onClick={()=>{
                            let params = check_inputs($("input.withdrawETH_params"),loading_withdrawETH);
                            if (params instanceof Array) {call_method("withdrawETH", params, setLoading_withdrawETH)}
                          }}>
                          {
                            loading_withdrawETH === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                          }
                          {t('exchangeContent.withdrawETH')}
                        </button>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.amount')} :</span>
                          <input param_type="amount" decimals="18" type="number" autoComplete="off" placeholder={t('exchangeContent.input_set_amount')} className="layui-input withdrawETH_params"></input>
                        </div>
                      </div>
                    </div>
                    <div className="layui-col-md3">
                      <div className="context-flex-column border_context" style={{'height':'auto'}}>
                        <button type="button" className="btn btn-primary" onClick={()=>{
                            let params = check_inputs($("input.updateExchangeRate"),loading_updateExchangeRate);
                            if (params instanceof Array) {call_method("updateExchangeRate", params, setLoading_updateExchangeRate)}
                          }}>
                          {
                            loading_updateExchangeRate === true ? <i className="layui-icon layui-icon-loading layui-anim layui-anim-rotate layui-anim-loop" style={{marginRight:5}}></i> :''
                          }
                          {t('exchangeContent.updateExchangeRate')}
                        </button>
                        <div style={{'display':'flex','justifyContent':'space-between','marginTop':5}}>
                          <span className="param_name">{t('exchangeContent.rate')} :</span>
                          <input param_type="uint" type="number" autoComplete="off" placeholder={t('exchangeContent.input_rate')} className="layui-input updateExchangeRate"></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default ExchangeSwap_b;
