/* global layui $ */
import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import { Select, Input, Button, message } from 'antd';

const { Option } = Select;
const key = 'loading';

const StakeModal = (props) => {
  const { t } = useTranslation('common');
  const loading_ref = useRef(false);
  const modalresult_ref = useRef(null);
  const quantityInput_ref = useRef(null);
  const [period, setPeriod] = useState(null);
  const [model_isApprove, setModal_isApprove] = useState(false);
  

  const handleChange = (value) => {
    setPeriod(value)
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const approve = async (token, spender, amount) => {
    let gas = await token.estimateGas.approve(spender, amount).catch((err) => {
        console.error(err)
    });
    if (typeof gas != 'undefined') {
        let gaslimit = Math.round(gas.toNumber() * 1.5);
        let tx = await token.approve(spender, amount, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
        return tx;
    }
  };

  const approvalForAll = async (nft, operator) => {
    let gas = await nft.estimateGas.setApprovalForAll(operator, true).catch((err) => {
        console.error(err)
    });
    if (typeof gas != 'undefined') {
        let gaslimit = Math.round(gas.toNumber() * 1.5);
        let tx = await nft.setApprovalForAll(operator, true, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
        return tx;
    }
  };

  const checkTxcheck = async (hash) => {
    let times = 30;
    while (times > 0) {
        let receipt = await props.myWallet.getTransactionReceipt(hash);
        if (receipt != null) {
            return receipt.status
        }
        await sleep(1000)
        times = times -1;
    }
  };

  const mintStakeECC = async (mode, quantity, ecc_stake) => {
    if (mode === 1) {
      let gas = await ecc_stake.estimateGas.stakeECC_1(quantity).catch((err) => {
        console.error(err)
      });
      if (typeof gas != 'undefined') {
          let gaslimit = Math.round(gas.toNumber() * 1.5);
          let tx = await ecc_stake.stakeECC_1(quantity, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
          return tx;
      }
    }
    else {
      let gas = await ecc_stake.estimateGas.stakeECC_2(quantity).catch((err) => {
        console.error(err)
      });
      if (typeof gas != 'undefined') {
          let gaslimit = Math.round(gas.toNumber() * 1.5);
          let tx = await ecc_stake.stakeECC_2(quantity, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
          return tx;
      }
    }
  }

  const mintStakeNFT = async (nft, tokenId, ecc_stakeNFT) => {
    let gas = await ecc_stakeNFT.estimateGas.stakeNFT(nft, tokenId).catch((err) => {
      console.error(err)
    });
    if (typeof gas != 'undefined') {
        let gaslimit = Math.round(gas.toNumber() * 1.5);
        let tx = await ecc_stakeNFT.stakeNFT(nft, tokenId, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
        return tx;
    }
  }

  const onClickModal_Approve = async () => {
    if (loading_ref.current) return;
    let quantity = null;
    if (period === null) {
      message.error({ content: t("stake.select_period"), key});
      return;
    }

    if (quantityInput_ref.current.input.value !== "") {
      quantity = ethers.utils.parseEther(quantityInput_ref.current.input.value)
    }
    else {
      message.error({ content: t("stake.input_Quantity"), key});
      return;
    }

    message.loading({ content: 'waiting approve ...', key , duration:0});
    loading_ref.current = true;
    let myWallet = props.myWallet;
    let account = await myWallet.connect();
    if (account === null) {
        message.error({ content: 'connect wallect failed!', key});
        loading_ref.current = false;
        return;
    }
    else {
      account = account[0];
    }


    let token = myWallet.getContract(props.ecc_addr);
    let eccStake = myWallet.getContract(props.eccStake);

    let balance = await token.balanceOf(account).catch(err => {console.error(err)});
    let allowance = await token.allowance(account, eccStake.address).catch(err => {console.error(err)});
    if (typeof balance !== "undefined" && typeof allowance !== "undefined") {
        if (balance.gte(quantity)) { // balance >= _price
            if (allowance.lt(quantity)) { // allowance < _price
                let tx = await approve(token, eccStake.address, quantity);
                if ( typeof tx !== "undefined") {
                    let status = await checkTxcheck(tx.hash);
                    if (status === 1) {
                        message.success({ content: 'approve is Successed!', key});
                        setModal_isApprove(true);
                        loading_ref.current = false;
                        return
                    }
                    else {
                        message.error({ content: 'approve is failed!', key});
                        loading_ref.current = false;
                        return
                    }
                }
                else {
                    message.error({ content: 'approve is failed!', key});
                    loading_ref.current = false;
                    return
                }
            }
            else {
                message.success({ content: 'approve is Successed!', key});
                setModal_isApprove(true);
                loading_ref.current = false;
                return
            }
        }
        else {
          message.error({ content: 'ECC balance is not enough', key});
          loading_ref.current = false;
          return
        }
    }
  }

  const onClickModal_ApproveNFT = async () => {
    if (loading_ref.current) return;
    if (period === null) {
      message.error({ content: t("stake.select_period"), key});
      return;
    }

    message.loading({ content: 'waiting approve ...', key , duration:0});
    loading_ref.current = true;
    let myWallet = props.myWallet;
    let account = await myWallet.connect();
    if (account === null) {
        message.error({ content: 'connect wallect failed!', key});
        loading_ref.current = false;
        return;
    }
    else {
      account = account[0];
    }

    let nft = myWallet.getContract(props.nft);
    let eccStakeNFT = myWallet.getContract(props.eccStakeNFT);
    let balance = await nft.balanceOf(account).catch(err => {console.error(err)});
    if (typeof balance !== "undefined") {
      if (balance.gt(ethers.utils.parseEther("0"))) { // balance > 0
        let isApprovedForAll = await nft.isApprovedForAll(account, eccStakeNFT.address).catch(err => {console.error(err)});
        if (typeof isApprovedForAll !== "undefined") {
          if (!isApprovedForAll) {
            let tx = await approvalForAll(nft, eccStakeNFT.address);
            if ( typeof tx !== "undefined") {
                let status = await checkTxcheck(tx.hash);
                if (status === 1) {
                  message.success({ content: 'approve is Successed!', key});
                  setModal_isApprove(true);
                  loading_ref.current = false;
                  return
                }
                else {
                  message.error({ content: 'approve is failed!', key});
                  loading_ref.current = false;
                  return
                }
            }
            else {
              message.error({ content: 'approve is failed!', key});
              loading_ref.current = false;
              return
            }
          }
          else {
            message.success({ content: 'approve is Successed!', key});
            setModal_isApprove(true);
            loading_ref.current = false;
            return
          }
        }
      }
      else {
        message.error({ content: 'NFT balance is ZERO', key});
        loading_ref.current = false;
        return
      }
    }
  }

  const onClickModal_Stake = async () => {
    if (loading_ref.current) return;

    let quantity = null;
    let stake_mode = null;
    if (period === null) {
      message.error({ content: t("stake.select_period"), key});
      return;
    }
    else {
      stake_mode = parseInt(period)
    }

    if (quantityInput_ref.current.input.value !== "") {
      quantity = ethers.utils.parseEther(quantityInput_ref.current.input.value)
    }
    else {
      message.error({ content: t("stake.input_Quantity"), key});
      return;
    }

    message.loading({ content: 'waiting stake ...', key , duration:0});
    loading_ref.current = true;
    let myWallet = props.myWallet;
    let account = await myWallet.connect();
    if (account === null) {
        message.error({ content: 'connect wallect failed!', key});
        loading_ref.current = false;
        return;
    }
    else {
      account = account[0];
    }

    let eccStake = myWallet.getContract(props.eccStake);

    let tx2 = await mintStakeECC(stake_mode, quantity, eccStake);
    if ( typeof tx2 !== "undefined") {
        let status2 = await checkTxcheck(tx2.hash);
        if (status2 === 1) {
            message.success({ content: 'Stake ECC Successed!', key});
            loading_ref.current = false;
            props.setModal_show(0)
            return
        }
        else {
            message.error({ content: 'Stake ECC faied!', key});
            loading_ref.current = false;
            return
        }
    }
    else {
        message.error({ content: 'Stake ECC is failed!', key});
        loading_ref.current = false;
        return
    }
  }

  const onClickModal_StakeNFT = async () => {
    if (loading_ref.current) return;

    message.loading({ content: 'waiting stake ...', key , duration:0});
    loading_ref.current = true;
    let myWallet = props.myWallet;
    let account = await myWallet.connect();
    if (account === null) {
        message.error({ content: 'connect wallect failed!', key});
        loading_ref.current = false;
        return;
    }
    else {
      account = account[0];
    }
    let nft = myWallet.getContract(props.nft);
    let eccStakeNFT = myWallet.getContract(props.eccStakeNFT);
    let tokenIds = await nft.walletOfOwner(account).catch(err => {console.error(err)});
    if (typeof tokenIds !== "undefined") {
      let tokenId = tokenIds[0];
      let tx2 = await mintStakeNFT(nft.address, tokenId, eccStakeNFT);
      if ( typeof tx2 !== "undefined") {
          let status2 = await checkTxcheck(tx2.hash);
          if (status2 === 1) {
              message.success({ content: 'Stake NFT Successed!', key});
              loading_ref.current = false;
              props.setModal_show(0)
              return
          }
          else {
              message.error({ content: 'Stake NFT faied!', key});
              loading_ref.current = false;
              return
          }
      }
      else {
          message.error({ content: 'Stake NFT is failed!', key});
          loading_ref.current = false;
          return
      }
    }
  }

  useEffect(() => {
  }, []);

  return (
    <div className='ModelBuy' show={props.modal_show} onClick={() => {
          if (loading_ref.current) return;
          props.setModal_show(0)
      }}>
      <div className='main main_modal'>
          <div className='box' style={{padding:10}} onClick={(e)=>{e.stopPropagation()}}>
              <div className='top modalTop'>
              {
                props.type === 0 ? <span>{t("stake.stack_ecc")}</span> : <span>{t("stake.stack_nft")}</span>
              }
              </div>
              <div className='bom'>
                  <div className='line'>
                  {
                    props.type === 0 ?  <Select style={{ width:'100%' }} placeholder="Select Days" onChange={handleChange} value={period}>
                                          <Option value="1">{t("stake.period_1")}</Option>
                                          <Option value="2">{t("stake.period_2")}</Option>
                                        </Select> :
                                        <Select style={{ width:'100%' }} placeholder="Select Days" onChange={handleChange} value={period}>
                                          <Option value="1">{t("stake.period_nft")}</Option>
                                        </Select>
                  }
                  </div>
                  {
                    props.type === 0 ? <div className='line'>
                                        <Input ref={quantityInput_ref} addonBefore="ECC :" type={"number"} placeholder={t("stake.input_Quantity")}/>
                                      </div> : ''
                  }
                  <Button style={{marginTop:20}} disabled={model_isApprove} type="primary" block onClick={() => {
                    if (props.type === 0) {onClickModal_Approve()} else {onClickModal_ApproveNFT()}
                  }} >{t("stake.approve")}</Button>
                  <Button style={{marginTop:10}} disabled={!model_isApprove} type="primary" block onClick={()=>{
                    if (props.type === 0) {onClickModal_Stake()} else {onClickModal_StakeNFT()}
                  }}>{t("stake.stack")}</Button>
              </div>
          </div>               
      </div>
    </div>
  );
}

export default StakeModal;
